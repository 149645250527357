import axios from "axios"

function connector(url,data){
    var resp;
    resp = axios.post(url, data)
    .then((response) =>response.data)
    .catch(err => console.log(err));
    
   // console.log(data);
    return resp;
}
export default connector