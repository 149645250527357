import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';
import data from "../../../data/yatirim"


// Layout
import Header from '../../layout/header/header1';
import Footer from '../../layout/footer/footer1';

// Images
import bannerPic1 from '../../../images/banner/yatırım.png';
import aboutPic3 from '../../../images/about/pic3.jpg';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import yatirimEN from '../../../data/yatirimEN';

function ServicesDetails() {
	const { t, i18n } = useTranslation();
	const currentLang = i18n.language

	let location = useLocation();
	let detailPageName = location.pathname.split("/")[2];
	console.log(detailPageName);
	let myObj;
	let urls = [];
	if (currentLang ==="tr") {
		data.forEach(element => {
			// console.log(element.url);
			if (element.url === detailPageName) {
				myObj = element
			}
	
		});
	
		data.forEach(function (obj) {
			urls.push(obj.url)
			// console.log(urls);
		})
	} else if (currentLang ==="en") {
		yatirimEN.forEach(element => {
			// console.log(element.url);
			if (element.url === detailPageName) {
				myObj = element
			}
	
		});
	
		yatirimEN.forEach(function (obj) {
			urls.push(obj.url)
			// console.log(urls);
		})
	}
console.log(location.pathname,"sekam");
	
// console.log(myObj);
	return (
		<>
			<Header />

			<div className="page-content bg-white">
				<div className="page-banner ovpr-dark overlay-dotted ovdt1 parallax" style={{ backgroundImage: "url(" + bannerPic1 + ")" }}>
					<div className="container">
						<div className="page-banner-entry">
							<h1 style={{ fontSize: "35px" }} className="text-white">{t('Global Yatırım')}</h1>
							<div className="breadcrumb-row">
								<ul className="list-inline">
									<li><Link to="/"><i className="fa fa-home"></i>{t('Anasayfa')}</Link></li>
									<li>{t('Yatırım Enstrümanları')}: {t('Çeşitlilik ve Fırsatlar')}</li>
								</ul>
							</div>
						</div>
					</div>
					<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
				</div>

				<div className="content-block" id="content-area">

					<div className="section-area section-sp1 bg-fix bg-gray">
						<div className="container about-video">
							<div className="row">
							<div className="col-lg-4 m-b30 wow fadeInUp" data-wow-delay="0.2s">
												<aside className="sticky-top">
													<div className="widget">
														<ul className="service-list">
															<li className="active"><Link to={"/services-2"}>{t('Diğer Global Yatırım Fırsatları')}</Link></li>
															<li><Link to={urls[0]}>{t('Emtialar')}</Link></li>
															<li><Link to={urls[1]}>{t('Hisse Senetleri')}</Link></li>
															<li><Link to={urls[2]}>Forex</Link></li>
															<li><Link to={urls[3]}>{t('Kripto Paralar')}</Link></li>
														</ul>
													</div>
													{/* <div className="widget">
											<div className="brochure-bx">
												<h5 className="title">{myObj.title}</h5>
												<p>Benefit of the socie where we oper ate success for the website done</p>
												<Link to={"#"} className="btn-secondry radius-xl button-md white">Download</Link>
											</div>
										</div> */}
												</aside>
											</div>

								
											
											<div className="col-lg-8 service-textarea">
												<h2 className="title m-b10">{myObj.title}</h2>
												{/* <p>{myObj.}</p> */}
												<div className="m-b30">
													<img  src={myObj.img} alt="" />
												</div>
												{/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p> */}
												<h3 className="m-b5">{myObj.descriptionTitle1}</h3>
												{/* <p>Standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p> */}
												<ul className="list-circle">
													<li>{myObj.description1}</li>

												</ul>
												<h3 className="m-b5">{myObj.descriptionTitle2}</h3>
												{/* <p>Standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p> */}
												<ul className="list-circle">
													<li>{myObj.description2}</li>

												</ul>
											</div>
									
								







							</div>
						</div>
					</div>

				</div>

			</div>

			<Footer />

		</>
	);

}

export default ServicesDetails;