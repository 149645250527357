import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Images
import IconImg1 from "../../images/icon/contact/icon1.png"
import IconImg2 from "../../images/icon/contact/icon2.png"
import IconImg3 from "../../images/icon/contact/icon3.png"
import Logo from "../../images/Nova-Logo.png"
import { withTranslation } from 'react-i18next';

class ContactSidebar extends Component{
	
	componentDidMount() {
		
		// Contact Sidebar
        var asideBtn = document.querySelector(".slideinfo")
        var contactSidebar = document.querySelector(".contact-sidebar")
        var closeBtn = document.getElementById("clase-btn")

        asideBtn.addEventListener('click',function(){
            contactSidebar.classList.add("open")
        })

        closeBtn.addEventListener('click',function(){
            contactSidebar.classList.remove("open")
        })        
    }
	
	render(){
		const { t } =this.props;
	
		return(
			<>
				<div className="slideinfo">
					{/* <ul className="slide-social">
						<li>Social Link</li>
						<li className="sc-link"><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
						<li className="sc-link"><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
						<li className="sc-link"><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
					</ul> */}
					<Link to={"#"} className="slider-contact btn contact-sidebtn">{t('İletişim')}</Link>
				</div>
				<div className="contact-sidebar content-scroll">
					<div className="inner">
						<div className="logo">
							<img style={{width:"100px", height:"90px"}} src={Logo} alt=""/>
						</div>
						<h4 className="title">{t('Bizimle İletişime Geçebilirsiniz!')}</h4>
						<ul className="contact-infolist">
							<li>
								<img src={IconImg1} alt=""/>
								<h6 className="contact-title">{t('İletişim Numaramız')}</h6>
								<p><a href='tel:+447597058146'>+44 75970 58146</a></p>
							</li>
							<li>
								<img src={IconImg2} alt=""/>
								<h6 className="contact-title">{t('Email Adresimiz')}</h6>
								<a href={"mailto:support@nova-markets.com"}>support@nova-markets.com</a>

							</li>
							<li>
								<img src={IconImg3} alt=""/>
								<h6 className="contact-title">{t('Adres')}</h6>
								<p>{t('22 Bishopsgate, London EC2N 4AJ Birleşik Krallık')}</p>

							</li>
						</ul>
						{/* <h4 className="title">Get In Touch</h4>
						<form className="contact-bx ajax-form">
							<div className="ajax-message"></div>
							<div className="row placeani">
								<div className="col-lg-12">
									<div className="form-group">
										<div className="input-group">
											<label>Your Name</label>
											<input name="name" type="text" required className="form-control"/>
										</div>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="form-group">
										<div className="input-group"> 
											<label>Your Email Address</label>
											<input name="email" type="email" className="form-control" required />
										</div>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="form-group">
										<div className="input-group">
											<label>Type Message</label>
											<textarea name="message" rows="4" className="form-control" required ></textarea>
										</div>
									</div>
								</div>
								<div className="col-lg-12">
									<button name="submit" type="submit" valueDefault="Submit" className="btn button-md"> Send Message</button>
								</div>
							</div>
						</form> */}
					</div>
				</div>
				<div className="contact-sidebtn close" id="clase-btn">
					<i className="ti-close"></i>
				</div>
			</>
		);
	}
}

export default withTranslation() (ContactSidebar);