import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import connector from "../../../data/connector"
import ReactInputMask from 'react-input-mask';

const FixedRegister = () => {
    const { t } = useTranslation();
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);

    let errorMessage = `${t('Bir Hata Oluştu! Lütfen sayfayı yenileyip tekrar deneyin.')}`;
    let successMessage = `${t('İsteğiniz Alındı! Ekibimiz en kısa süre içerisinde sizinle iletişime geçecektir.')}`
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("")
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [validator, setValidator] = useState(false);
    const [kvkk, setKvkk] = useState(false);
    const [isSend, setIsSend] = useState(false);
    const [formUrl, setFormUrl] = useState()
    const [alerts, setAlerts] = useState("")
    const [alertType, setAlertType] = useState("")

    useEffect(() => {
        setFormUrl(`${'https://' + (window.location.hostname.replace('www.', '')) + "/api/v1/Submit?brand=2"} `)
        // setFormUrl(`${'https://' + "nova.testmedici.com" + "/api/v1/Submit?brand=2"} `)
    })

    let data = {
        "firstName": name,
        "lastName": surname,
        "phone": String(phone).replace(" ", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", ""),
        "email": email,
        "approvedConditions": kvkk,
    }

    function EmailChange(e) {
        const validEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        let isOk = validEmail.test(String(e).toLowerCase())
        if (isOk) {
            setEmail(e);
            setValidator(true)
        }
        else {
            setEmail(e);
            setValidator(false)
        }
    }
    async function Submit(e) {
        e.preventDefault();
        setIsSend(true)
        connector(formUrl, data)
            .then(data => {
                if (data.success) {
                    setAlerts(successMessage)
                    setAlertType("success")
                    setIsSend(false);
                    setName("")
                    setSurname("")
                    setEmail("")
                    setPhone("")
                    setTimeout(() => {
                        setAlerts("")
                    }, 1500);
                }
            })
            .catch(err => {
                console.log(err);
                setAlerts(errorMessage)
                setAlertType("error")
                setIsSend(false);
                setTimeout(() => {
                    setAlerts("")
                }, 1500);
            })
    }

    // const handleScroll = () => {
    //     if (window.scrollY < lastScrollY) {
    //         // Yukarı doğru scroll ediyor
    //         setIsFormVisible(true);
    //     } else {
    //         // Aşağı doğru scroll ediyor
    //         setIsFormVisible(false);
    //     }
    //     setLastScrollY(window.scrollY);
    // };

    const handleClose = () => {
        setIsFormVisible(false);
    };

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [lastScrollY]);

    return (
        <div className='page-register-form shadow'>
            <div className="page-register-header">
                <h3 className="mb-0 text-center">
                    {t("Hesap Oluştur")}
                </h3>
                {/* <button className="close-btn" onClick={handleClose}>×</button> */}
            </div>
            <div className={`page-register-form-content ${isFormVisible ? 'visible' : ''}`}>
                <form className='mt-5' onSubmit={(e) => Submit(e)}>
                    <div className='row'>
                        <div className="col-lg-3">
                            <label>{t('İsim')}</label>
                            <input
                                type="text"
                                name="name"
                                placeholder={`${t('İsim')}`}
                                className="form-control"
                                required
                                value={name}
                                onChange={event => { setName(event.target.value) }}
                            />
                        </div>
                        <div className="col-lg-3">
                            <label>{t('Soyisim')}</label>
                            <input
                                type="text"
                                name="name"
                                placeholder={`${t('Soyisim')}`}
                                className="form-control"
                                required
                                value={surname}
                                onChange={event => { setSurname(event.target.value) }}
                            />
                        </div>
                        <div className="col-lg-3">
                            <label>{t('E-Mail Adresi')}</label>
                            <input
                                type="text"
                                name="name"
                                placeholder={`${t('E-Mail Adresi')}`}
                                className="form-control"
                                required
                                value={email}
                                onChange={event => { EmailChange(event.target.value) }}
                            />
                        </div>
                        <div className="col-lg-3">
                            <label>{t('Telefon Numarası')}</label>
                            <ReactInputMask
                                mask="(999) 999 99 99"
                                type="text"
                                required
                                className="form-control"
                                value={phone}
                                onChange={event => { setPhone(event.target.value) }}
                                placeholder={`${t('Telefon Numarası')}`} />
                        </div>
                    </div>
                    <div className='my-3'>
                        <input
                            required
                            type="checkbox"
                            onClick={() => setKvkk(kvkk ? false : true)}
                        />
                        <span className='ms-2'>
                            <a href='/kvkk' target='_blank' style={{ textDecoration: "underline" }}>{t('Kişisel Verilerin Korunması ve İşlenmesi Sözleşmesi')}</a>
                        </span>
                    </div>
                    <div className=''>
                        <button
                            type="submit"
                            value="submit"
                            className="btn default-btn"
                            disabled={isSend}
                        >
                            {!isSend ? t('Kayıt Ol') : t("Gönderiliyor")}
                        </button>
                    </div>
                    {
                        alertType !== "" &&
                        <div className="form-group">
                            <p className={`${alertType === "success" ? "text-success" : "text-danger"}`}>{alerts}</p>
                        </div>
                    }
                </form>
            </div>
        </div>
    );
};

export default FixedRegister;
