import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Images
import logo from '../../../images/Nova-Logo (1).png';
import { useTranslation } from 'react-i18next';

function Footer1() {
	var tarih = new Date();
	var guncelTarih = tarih.getFullYear()
	const { t, i18n } = useTranslation();
	return (
		<>
			<footer>


				<div className="footer-top">
					<div className="container">
						<div className="row">
							<div className='col-lg-12 col-12' style={{ display: "flex", justifyContent: "center" }}>

								<div className="col-12 col-lg-8 col-md-12 col-sm-12 order-2 order-lg-1" >
									<div style={{ display: "flex", justifyContent: "center" }}>
										<div className="row" >
											<div className="col-12 col-lg-5 col-md-6 col-sm-6">
												<div className="widget list-2">
													<h5 className="footer-title">{t('Faydalı Linkler')}</h5>
													<ul>
														<li><Link to={"/"}>{t('Anasayfa')}</Link></li>
														<li><Link to={"services-2"}>{t('Yatırım')}</Link></li>
														<li><Link to={"about-2"}>{t('Hakkımızda')}</Link></li>
														<li><Link to={"campaigns"}>{t('Promosyonlar')}</Link></li>
														<li><Link to={"contact-1"}>{t('İletişim')}</Link></li>
														<li><Link to={"company-history-2"}>{t('Nova Invest Avantajları')}</Link></li>
														{/* <li><Link to={"team"}>Our Team</Link></li> */}
													</ul>
												</div>
											</div>
											<div className="col-12 col-lg-7 col-md-6 col-sm-6">
												<div className="widget footer_talkus">
													<h5 className="footer-title">{t('Adres')}</h5>
													<p>{t('22 Bishopsgate, London EC2N 4AJ Birleşik Krallık')}</p>
													<h5 className="footer-title">{t('İletişim')}</h5>
													<p><a href='mailto:support@nova-markets.com'>support@nova-markets.com</a><br /><a href='tel:+447597058146'>+44 75970 58146</a></p>
												</div>
											</div>
											<div className="col-12 col-lg-12 col-md-12 col-sm-12">
												<div className="footer-innner-btm">
													<div className="footer-logo">
														<Link to={"/"}><img src={logo} alt="" /></Link>
													</div>
													<div className="copyright-bx">
														<p>© {guncelTarih} {t('Nova Invest')}. {t('Tüm Hakları Saklıdır')}</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* <div className="col-12 col-lg-4 col-md-12 col-sm-12 order-1 order-lg-2">
									<div className="subscribe-form">
										<h5 className="footer-title">Sign Up For A Newsletter</h5>
										<p className="text-capitalize m-b20">Weekly Breaking news analysis and cutting edge advices on job searching.</p>
										<form className="subscription-form">
											<div className="ajax-message"></div>
											<input name="email" required="required"  className="form-control" placeholder="Your Email Address" type="email"/>
											<button name="submit" defaultValue="Submit" type="submit" className="btn w-100 black">Submit</button>	
										</form>
										<h5 className="footer-title">Follow Us</h5>
										<ul className="list-inline ft-social-bx">
											<li><Link to={"#"} className="btn button-sm"><i className="fa fa-facebook"></i></Link></li>
											<li><Link to={"#"} className="btn button-sm"><i className="fa fa-twitter"></i></Link></li>
											<li><Link to={"#"} className="btn button-sm"><i className="fa fa-linkedin"></i></Link></li>
											<li><Link to={"#"} className="btn button-sm"><i className="fa fa-google-plus"></i></Link></li>
										</ul>
									</div>
								</div> */}
							</div>
						</div>
					</div>
				</div>

			</footer>

		</>
	);

}

export default Footer1;
