import img1 from "../images/yatirim/1.jpeg"
import img2 from "../images/yatirim/2.jpeg"
import img3 from "../images/yatirim/3.jpeg"
import img4 from "../images/yatirim/4.jpeg"


const yatirim = [{
    id: 1,
    img:img1,
    url:"emtialar",
    title: "Emtialar",
    descriptionTitle1: "Altın ve Gümüş",
    descriptionTitle2:"Tarım Ürünleri",
    description1:"Değer saklama amacıyla tercih edilen kıymetli metaller, ekonomik belirsizlik dönemlerinde güvenli liman olarak bilinir.",
    description2:"Buğday, pamuk, kahve gibi tarım ürünleri, dünya genelindeki gıda talepleri ve hava koşulları gibi faktörlere bağlı olarak hareketlenebilir."
    
},
{
    id:2,
    img:img2,
    url:"hisse-senetleri",
    title:"Hisse Senetleri",
    descriptionTitle1: "Teknoloji Şirketleri",
    descriptionTitle2:"Enerji Şirketleri",
    description1:"Yenilikçi ve büyüme potansiyeli yüksek teknoloji şirketleri, hisse senedi portföylerine değer katabilir.",
    description2:"Petrol ve doğalgaz gibi enerji şirketleri, enerji talebinin etkisiyle yatırımcılara fırsat sunabilir."
},
{
    id:3,
    img:img3,
    url:"forex",
    title:"Forex (Döviz Kurları)",
    descriptionTitle1: "EUR/USD, GBP/JPY gibi Pariteler",
    descriptionTitle2:"USD/TRY, EUR/TRY gibi Türk Lirası Pariteleri",
    description1:"Küresel ekonomik gelişmeler ve merkez bankası politikaları, döviz kurlarını etkileyebilir.",
    description2:"Türk Lirası'nın değeri üzerindeki etkileri değerlendirmek adına Türk Lirası pariteleri de bir seçenek olabilir."
},
{
    id:4,
    img:img4,
    url:"kripto-paralar",
    title:"Kripto Paralar",
    descriptionTitle1: "Bitcoin (BTC), Ethereum (ETH)",
    descriptionTitle2:"Altcoin'ler",
    description1:"Blockchain teknolojisi üzerine kurulu olan kripto paralar, dijital varlıkların geleceğinde önemli bir rol oynayabilir.",
    description2:"Litecoin (LTC), Ripple (XRP) gibi çeşitli altcoin'ler, farklı blockchain projelerini temsil eder."
}
]

export default yatirim