import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll'
import ModalVideo from 'react-modal-video'
import { withTranslation } from 'react-i18next';

// Layout
import Header from "../../layout/header/header1";
import Footer from "../../layout/footer/footer1";

// Elements
import Team from '../../elements/team/team2';
import Testimonial from "../../elements/testimonial/testimonial2";
import ClientLogo from "../../elements/client-logo/logo-carousel";
import Counter from "../../elements/counter/counter2";

// Images
import bannerPic2 from '../../../images/banner/hakkımızda.png';
import bgPic1 from '../../../images/background/bg1.jpg';
import bgPic2 from '../../../images/background/bg2.jpg';
import pattern1 from '../../../images/pattern/pt1.png';
import aboutPic1 from '../../../images/about/hakkıımızda.png';
import aboutPic2 from '../../../images/about/mini-hakkımızda.png';

class About2 extends Component {

	constructor() {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
		this.state = {
			isOpen: true
		}
		this.openAbout = this.openAbout.bind(this)

	}
	openAbout() {
		this.setState({ isOpen: true })
	}
	openModal() {
		this.setState({ isOpen: true })
	}

	render() {
		const { t } = this.props;

		return (
			<>
				<Header />

				<div className="page-content bg-white">
					<div className="page-banner ovbl-dark page-banner-lg ovdt1 overlay-dotted no-pt parallax" style={{ backgroundImage: "url(" + bannerPic2 + ")" }}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 style={{fontSize: "24px"}} className="banner-sm-title">{t('Hakkımızda')}</h1>
								<span style={{fontWeight: "600", fontSize: "20px"}} className="text-white">{t('Nova Invest olarak, küresel perspektifimizle uluslararası finans piyasalarındaki dinamikleri yakından takip ediyor ve yatırımcılarımıza dünya genelindeki yatırım fırsatlarına erişim sağlıyoruz.')}</span>
							</div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
					</div>
					<div className="content-block" id="content-area">

						<div className="section-area section-sp2 bg-white" style={{ backgroundImage: "url(" + pattern1 + ")" }}>
							<div className="container">
								<div className="row align-items-center">
									<div className="col-lg-6 col-md-12 wow fadeIn" data-wow-delay="0.3s">
										<div className="heading-bx">
											<h2 className="title-head m-b0">{t('Nova Invest')}</h2>
											<p className="m-b0"><span style={{ fontWeight: "bold" }}>{t('Deneyimli Ekibimiz')}:</span>
												{t('Nova Invest, finans dünyasında uzun yıllara dayanan deneyime sahip bir ekip ile yoluna devam etmektedir. Her bir üye, sektördeki güncel gelişmeleri yakından takip ederek yatırımcılara en iyi kullanıcı deneyimini sunma konusunda uzmandır.')}
												<br />

												<span style={{ fontWeight: "bold" }}>{t('Yatırımcı Odaklı Yaklaşım')}:</span>
												{t('Yatırımcı memnuniyetini en üst düzeyde tutmayı amaçlıyoruz. Nova Invest olarak, her bir yatırımcının ihtiyacını anlamak ve onlara özel çözümler sunmak için çalışıyoruz.')}
												<br />

												<span style={{ fontWeight: "bold" }}>{t('Güvenilirlik ve Şeffaflık')}:</span>
												{t('Nova Invest, güvenilirlik ilkesini benimsemekte ve yatırımcılarına şeffaf bir hizmet sunmaktadır. İşlemlerimizde açıklık prensibine dayanarak, yatırımcılarımızın bize tam anlamıyla güvenmelerini sağlıyoruz.')}
												<br />

												<span style={{ fontWeight: "bold" }}>{t('İnovasyon ve Teknoloji')}:</span>
												{t("Sektördeki en son teknolojik gelişmeleri takip ediyoruz ve bu teknolojileri kullanarak yatırımcılarımıza daha etkili çözümler sunuyoruz. İnovasyon, Nova Invest'ın temel prensiplerinden biridir.")}
												<br />

												<span style={{ fontWeight: "bold" }}>{t('Kişiselleştirilmiş Yatırım Stratejileri')}:</span>
												{t('Her yatırımcının finansal hedefleri farklıdır. Nova Invest, yatırımcılarının özel ihtiyaçlarını anlayarak kişiselleştirilmiş yatırım stratejileri oluşturur ve bu stratejilerle yatırımcılarının başarıya ulaşmasına katkıda bulunur.')}
												<br />
												<br />

											</p>
											{/* <p className="m-b30">Printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the simply dummy text of the printing and  dummy text of the printing and typesetting industry typesetting industry simply.</p> */}
											<Link to={"contact-1"} className="btn button-md radius-xl">{t('Bizimle İletişime Geçin!')}</Link>
										</div>
									</div>
									<div className="col-lg-6 col-md-12 wow fadeIn" data-wow-delay="0.6s">
										<div className="about-img-box">
											<img src={aboutPic1} alt="" />
											<img src={aboutPic2} alt="" />


										</div>
									</div>
								</div>
							</div>
						</div>





						<div className='mission-vision' style={{padding:"0px", display: "flex", fontSize: "16px", padding: "15px", justifyContent: "center" }}>

							<div className="timeline-box col-lg-5" id="2012">
								<div className="heading-bx" style={{display:"flex", textAlign:"center",justifyContent:"center"}}>
									<h4 style={{color:"#AD66CF"}}>{t('Vizyonumuz')}</h4>

								</div>
								<ul className="mission-vision">
									<li>{t('Nova Invest olarak, sürekli olarak kendimizi güncelleme gayreti içerisindeyiz. Sektördeki değişimlere hızla adapte oluyor ve yatırımcılarımıza her zaman en güncel bilgileri ve hizmetleri sunmaya özen gösteriyoruz.')}

									</li>
									<br />
									<li>{t('Geniş Yatırım Yelpazesi')}:<br/>{t('Nova Invest, yatırımcılarına çeşitli finansal enstrümanlarda geniş bir yatırım yelpazesi sunmaktadır. Forex, emtia, hisse senetleri ve kripto paralar gibi farklı varlık sınıflarında çeşitlendirilmiş yatırım imkanlarıyla, yatırımcılarımıza kendi tercihlerine uygun seçenekleri değerlendirme fırsatı veriyoruz. Bu çeşitlilik, yatırımcılara esneklik sağlayarak farklı piyasa koşullarına adapte olmalarını ve portföylerini çeşitlendirmelerini sağlıyor.Nova Invest olarak, iş ortaklarımızın finansal hedeflerine ulaşmalarına destek olmak adına geniş bir yatırım ürün yelpazesi sunmaktan gurur duyuyoruz. Siz de Nova Invest ile farklı varlık sınıflarında yatırım yaparak portföyünüzü çeşitlendirebilir ve yatırım stratejilerinizi optimize edebilirsiniz.')}
									</li>
									<br />
									<li>{t('Teknik Analiz Eğitimleri')}: <br/>
									{t('Nova Invest, yatırımcılarına finansal piyasalarda daha bilinçli ve başarılı yatırımlar yapabilmeleri için teknik analiz eğitimleri sunmaktadır. Uzman analistlerimiz tarafından verilen bu eğitimlerle, yatırımcılarımız piyasa trendlerini daha iyi anlamalarını, grafikleri etkili bir şekilde okumalarını ve doğru yatırım kararları vermelerini sağlayacak temel yetkinliklere sahip olabilirler. Nova Invest, yatırımcılarını finansal okuryazarlık konusunda destekleyerek, kendi stratejilerini oluşturmalarına yardımcı olmaktadır.')}
									</li>
									<br />
									<li>{t('Global Perspektif')}: <br/>
									{t('Nova Invest, yatırımcılarına küresel finans piyasalarındaki fırsatları değerlendirme imkanı sunar. Küresel ekonomik gelişmeleri yakından takip eden ve uluslararası piyasalardaki dinamiklere hakim olan analistlerimiz, yatırımcılarımıza dünya genelinde çeşitli yatırım fırsatları hakkında bilgi sağlar. Bu global perspektif, yatırımcılarımızın portföylerini çeşitlendirmelerine ve uluslararası piyasalardaki potansiyel getirilere erişmelerine olanak tanır.')}
									</li>
									
								</ul>

							</div>

							<div className="timeline-box col-lg-5" id="2012">
								<div className="heading-bx" style={{display:"flex", textAlign:"center",justifyContent:"center"}}>
									<h4 style={{color:"#AD66CF"}}>{t('Misyonumuz')}</h4>

								</div>
								<ul className=" mission-vision">
									<li>{t('Nova Invest olarak finans dünyasında; kalite, güven ve inovasyonun bir araya getirmeyi misyon edindik. Geçmişteki başarılı deneyimlerimizden ilham alarak, yatırımlarınıza yeni bir perspektif sunmayı hedefliyoruz. Yatırımcı odaklı yaklaşımımız ve uzman kadromuzla, finansal hedeflerinize ulaşmanız için size özel çözümler sunuyoruz.')}</li>
										<br/>
									<li>
										{t('Nova Invest olarak, sektörel değişimlere ayak uyduruyor ve her geçen gün kendimizi güncelliyoruz. Sektördeki en güncel bilgilerle donanmış olan ekibimiz, size en yüksek kullanıcı deneyimini sunabilmek adına çalışmaya devam ediyoruz.')}</li>
									<br />
									<li>{t('Yenilikçi ve güçlü bir geleceğe adım atmaya hazır mısınız? Nova Invest olarak, sizi başarıya taşıyacak adımları atmaktan mutluluk duyuyoruz. Finansal hedeflerinizi birlikte gerçekleştirmek için buradayız. Nova Invest ile geleceğinizi birlikte inşa edelim!')}</li>
									<br />
									
								</ul>
								
							</div>

						</div>



						<div className="section-area section-sp2 ovpr-dark about-video2 parallax" style={{ backgroundImage: "url(" + bgPic2 + ")", backgroundSize: "cover" }}>
							<div className="container" >
								{/* <div className="video-media-bx">
									<Link to={"#"} onClick={this.openModal} className="popup-youtube video-zoom"><i className="fa fa-play"></i></Link>
								</div> */}
								<div style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
									<h1 style={{ color: "white" }}>{t("Her Anınızı Geleceğe Dönüştüren Yatırım Deneyimi Nova Invest'de !")}</h1>

								</div>
								<div style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
									<Link to={"contact-1"} className="btn button-md radius-xl">{t('Bizimle İletişime Geçin!')}</Link>

								</div>


							</div>
						</div>

						{/* <div className="section-area bg-gray section-sp1">
							<div className="container">
								<Counter />
							</div>
						</div> */}

						{/* <div className="section-area section-sp1" style={{backgroundImage:"url("+pattern1+")"}}>
							<div className="container">
								<div className="heading-bx text-center wow fadeIn" data-wow-delay="0.3s">
									<h2 className="title-head m-b0">Meet Our Awesome <br/>Team Mamber</h2>
									<p className="m-b0">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
								</div>
								<Team />
							</div>
						</div> */}

						{/* <div className="section-area section-sp2 ovpr-dark about-video2 bg-fix" style={{backgroundImage:"url("+bgPic1+")", backgroundPosition:"top",}}>
							<div className="container">
								<div className="heading-bx text-center text-white">
									<h2 className="title-head m-b0">What clients talk about us</h2>
									<p className="m-b0">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
								</div>
								
								<Testimonial />
							</div>
						</div> */}

						{/* <div className="section-area section-sp2" style={{backgroundImage: "url("+pattern1+")"}}>
							<div className="container">
								<div className="heading-bx text-center">
									<h2 className="title-head m-b0">We Are Known By The <br/>Companies We Keep</h2>
									<p className="m-b0">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
								</div>
								<ClientLogo />
							</div>
						</div> */}

					</div>
				</div>

				<Footer />

				{/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='xneM6b83KCA' onClose={() => this.setState({isOpen: false})} /> */}

			</>
		);
	}
}

export default withTranslation()(About2);