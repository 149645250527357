import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';
import ModalVideo from 'react-modal-video'
import { withTranslation } from 'react-i18next';

// Layout
import Header from "../../layout/header/header1";
import Footer from "../../layout/footer/footer1";

// Elements
import Count from '../../elements/counter/counter-sensor';
import ClientLogo from "../../elements/client-logo/logo-carousel";
import playStore from "../../../images/play-store.png"
import appStore from "../../../images/app-store.png"

// Images
import bannerPic1 from '../../../images/banner/islem-platformlari.png';
import pattern1 from '../../../images/pattern/pt1.png';
import aboutPic4 from '../../../images/about/pic4.jpg';
import aboutPic5 from '../../../images/about/pic5.jpg';
import aboutPic6 from '../../../images/about/trading.png';
import bgPic2 from '../../../images/background/yatırıma-yonlenecek.png';
import aboutPic2 from '../../../images/about/about2.png';

class CompanyHistory1 extends Component {

	constructor() {
		super()
		this.state = {
			isOpen: false,
			apps: []
		}
		this.openModal = this.openModal.bind(this)
	}
	openModal() {
		this.setState({ isOpen: true })
	}

	componentDidMount() {
		const location = window.location.origin;
		let url;

		if (location.includes("localhost") || location.includes("test")) {
			url = "https://nova.testmedici.com/api/Global/Apps"
		} else {
			url = `${location}/api/Global/Apps`
		}

		fetch(url)
			.then(response => {
				if (!response.ok) {
					throw new Error('Network response was not ok ' + response.statusText);
				}
				return response.json();
			})
			.then(data => {
				this.setState({
					apps: data,
				});
				console.log(this.state.apps)
			})
			.catch(error => {
				console.log("error message", error)
			});
	}

	render() {
		const { t } = this.props;
		const { apps } = this.state;
		return (
			<>

				<Header />

				<div className="page-content bg-white">
					<div className="page-banner ovpr-dark overlay-dotted ovdt2 parallax" style={{ backgroundImage: "url(" + bannerPic1 + ")" }}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">{t('İşlem Platformları')}</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i>{t('Anasayfa')}</Link></li>
										<li>{t('İşlem Platformları')}</li>
									</ul>
								</div>
							</div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
					</div>
					<div className="content-block" id="content-area">

						<div className="section-area section-sp1 bg-gray" style={{ backgroundImage: "url(" + pattern1 + ")" }}>
							<div className="container">
								<div className="row">
									{/* <div className="col-md-3 wow timeline-nav-box">
										<div className="scroll-page timeline-nav sticky-top">
											<ul className="navbar">
												<li><ScrollTo smooth={true} activeClass="active" spy={true} className="nav-link" to={"2018"}>2018</ScrollTo></li>
												<li><ScrollTo smooth={true} activeClass="active" spy={true} className="nav-link" to={"2017"}>2017</ScrollTo></li>
												<li><ScrollTo smooth={true} activeClass="active" spy={true} className="nav-link" to={"2014"}>2014</ScrollTo></li>
												<li><ScrollTo smooth={true} activeClass="active" spy={true} className="nav-link" to={"2012"}>2012</ScrollTo></li>
												<li><ScrollTo smooth={true} activeClass="active" spy={true} className="nav-link" to={"2007"}>2007</ScrollTo></li>
											</ul>
										</div>
									</div> */}
									<div className="col-md-12">
										<div className="timeline-box" id="2018">
											<div className="heading-bx">
												<h2 className="title-head m-b0">{t('Sirix İşlem Platformu: Yatırımlarınızı Yönetmenin Modern Yolu')}</h2>
												<p className="m-b0">{t('Finansal piyasalarda başarılı bir yatırımcı olmak için doğru araçlara sahip olmak hayati önem taşır. Sirix işlem platformu, modern özellikleri ve kullanıcı dostu arayüzüyle, yatırımcılara etkili bir şekilde varlık yönetme imkanı sunan bir platform olarak öne çıkar.')}</p>
											</div>
											<div className="video-media-bx m-b30" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
												<img style={{ borderRadius: "15px" }} src={aboutPic6} alt="" />
												{/* <Link to={"#"} onClick={this.openModal} className="popup-youtube"><i className="fa fa-play"></i></Link> */}
											</div>

										</div>


										<div className="timeline-box" id="2012">
											<div className="heading-bx">
												{/* <p className="m-b0">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p> */}
											</div>
											<ul className="list-circle">
												<li>{t('1. Gelişmiş Teknik Analiz Araçları')}</li>
												<p className='m-b0'>{t('Sirix, yatırımcılara finansal enstrümanları analiz etmek ve gelecekteki fiyat hareketlerini tahmin etmek için gelişmiş teknik analiz araçları sağlar. Grafik çeşitliliği, göstergeler, çizim araçları ve analiz fonksiyonları, kullanıcıların daha bilinçli ve stratejik yatırım kararları almasını kolaylaştırır.')}</p><br />
												<li>{t('2. Kolay Kullanım ve Hızlı İşlem')}</li>
												<p className='m-b0'>{t('Sirix, sezgisel bir arayüzü ve hızlı işlem yürütme özellikleriyle dikkat çeker. Kullanıcılar, tek tıkla işlem yapma, anında emirleri değiştirme ve piyasa koşullarına hızlı bir şekilde tepki gösterme imkanına sahiptirler. Bu özellikler, anlık fiyat değişimlerine hızlı bir şekilde tepki verme yeteneğini artırarak yatırımcılara avantaj sağlar.')}</p><br />
												<li>{t('3. Çeşitli Varlık Sınıfları')}</li>
												<p className='m-b0'>{t('Sirix, geniş bir varlık yelpazesi sunar. Forex, hisse senetleri, endeksler, emtialar ve kripto paralar gibi farklı varlık sınıflarında işlem yapma imkanı tanır. Bu çeşitlilik, yatırımcıların portföylerini çeşitlendirmelerine ve farklı piyasalardan faydalanmalarına olanak sağlar.')}</p><br />
												<li>{t('4. Mobil Uyumlu ve Web Tabanlı')}</li>
												<p className='m-b0'>{t('Sirix, web tabanlı bir platform olmasıyla bilgisayar kullanıcılarına ek bir avantaj sunarken, mobil uygulama ile de yatırımcılara hareket halindeyken bile piyasayı takip etme ve işlem yapma imkanı verir.')}</p><br />
											</ul>
											<p className='m-b0'>{t("Sirix işlem platformu, finansal piyasalarda etkin bir şekilde işlem yapmak isteyen yatırımcılar için modern, güvenilir ve kullanıcı dostu bir çözüm sunar. Siz de yatırımlarınızı daha etkili bir şekilde yönetmek için Sirix'i keşfedin!")}</p>
										</div>

										<div className="section-area section-sp3 ovpr-dark bg-fix about-media-bx parallax" style={{ backgroundImage: "url(" + bgPic2 + ")" }}>
											<div className="container">
												<div className="row">
													<div className="col-lg-6 wow fadeInUp m-md-b30" data-wow-delay="0.4s">
														<div className="video-media-bx">
															<img src={aboutPic2} alt="" />
															{/* <Link to={"#"} onClick={this.openModal} className="popup-youtube"><i className="fa fa-play"></i></Link> */}
														</div>
													</div>
													<div className="col-lg-6 wow fadeIn" data-wow-delay="0.8s">
														<div className="heading-bx text-white m-t20">
															<h2 className="m-b10">{t('İşlemlerinizi Uygulamanız ile Rahatça Gerçekleştirin!')}</h2><br />
															<a href={apps.android} target='_blank'>
																<img style={{ width: "200px" }} src={playStore} />
															</a>

															<a href={apps.ios} target='_blank'>
																<img style={{ width: "200px" }} src={appStore} />
															</a>
														</div>

													</div>
												</div>
											</div>
										</div>

									</div>
								</div>

							</div>
						</div>

					</div>
				</div>

				<Footer />

				<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='xneM6b83KCA' onClose={() => this.setState({ isOpen: false })} />

			</>
		);
	}
}

export default withTranslation()(CompanyHistory1);