import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';
import data from "../../../data/yatirim"
import kampanya from '../../../data/kampanya';
import { withTranslation } from 'react-i18next';
// Images
import logo from '../../../images/Nova-Logo.png';
import logoWhite from '../../../images/Nova-Logo (1).png';
import adv from '../../../images/adv/adv.jpg';
import i18n from '../../../i18n';
import trflag from "../../../images/turkey.png"
import enflag from "../../../images/united-kingdom.png"
import Select from 'react-select';
import yatirimEN from '../../../data/yatirimEN';
import kampanyaEN from '../../../data/kampanyaEN';



const CustomOption = ({ innerProps, label, data }) => (
	<div {...innerProps} >
		{data.image && <img src={data.image} alt={label} style={{ width: "30px", marginBottom: "5px", marginLeft: "15px", marginRight: "8px" }} />}
		{label}
	</div>
);
class Header extends Component {
	options = [
		{ value: 'en', label: 'EN', image: enflag },
		{ value: 'tr', label: 'TR', image: trflag },
	];
	constructor(props) {
		super(props);

		this.state = {
			urls: [],
		};
		
	}

	componentDidMount() {
		// Search Form Popup
		var searchBtn = document.getElementById("quik-search-btn")
		var searchForm = document.querySelector(".nav-search-bar")
		var closeBtn = document.getElementById("search-remove")

		searchBtn.addEventListener('click', function () {
			searchForm.classList.add("show")
		})
		closeBtn.addEventListener('click', function () {
			searchForm.classList.remove("show")
		})

		var menuIcon = document.querySelector(".menuicon")
		var menuLinks = document.querySelector(".menu-links")
		var menuClose = document.getElementById("menuClose")

		menuIcon.addEventListener('click', function () {
			menuLinks.classList.add("show")
		})
		menuClose.addEventListener('click', function () {
			menuLinks.classList.remove("show")
		})

		// Mobile Submenu Open Close Function
		var navMenu = [].slice.call(document.querySelectorAll('.menu-links > ul > li'));
		for (var y = 0; y < navMenu.length; y++) {
			navMenu[y].addEventListener('click', function () { menuClick(this) });
		}

		function menuClick(current) {
			const active = current.classList.contains("open")
			navMenu.forEach(el => el.classList.remove('open'));

			if (active) {
				current.classList.remove('open')
				// console.log("active")
			} else {
				current.classList.add('open');
				// console.log("close")
			}
		}
		data.forEach((obj) => {
			this.setState((prevState) => ({
				urls: [...prevState.urls, obj.url],
			}));
		});

		

		localStorage.setItem("currentLang", i18n.language);
		
		
		//localStorage.setItem("currentLang", "en");

	}

	



	changeLanguageHandler  = (selectedOption) => {
		const { i18n } = this.props;
		i18n.changeLanguage(selectedOption.value);
		// const currentLang = selectedOption.value
		//const currentLang = localStorage.getItem("currentLang");
		localStorage.setItem("currentLang", i18n.language)

		
		// console.log(localStorage.getItem("currentLang"))


	}

	render() {

		const { urls } = this.state;
		//var sca = (`${'https://sca.' + (window.location.hostname.replace('www.', ''))}` + '.com')
		var sca = (`${'https://sca.' + (window.location.hostname.replace('www.', ''))}`)
		const { t } = this.props;

		// console.log(this.props);

		//const currentLang = localStorage.getItem("currentLang")
		const currentLang = i18n.language;
		if (currentLang ===null || currentLang ==="") {
			currentLang = "en"
		}

		// console.log(currentLang, "selam");
		
		//Test Environment
		if (sca.includes("test") || sca.includes("localhost")) {
			sca = "https://scanova.testmedici.com"
		}


		// const { urlsCampaign } = this.state;
		// console.log(urlsCampaign);
		return (
			<>
				{/* <!-- Header Top ==== --> */}
				<header className="header rs-nav header-transparent">
					<Sticky enabled={true} className="sticky-header navbar-expand-lg">
						<div className="menu-bar clearfix">
							<div className="container-fluid clearfix">
								{/* <!-- Header Logo ==== --> */}
								<div className="menu-logo">
									<Link to={"/"}><img style={{ maxWidth: "241px", height: "105px" }} src={logoWhite} alt="" /></Link>
								</div>
								{/* <!-- Mobile Nav Button ==== --> */}
								<button className="navbar-toggler collapsed menuicon justify-content-end" type="button" data-toggle="collapse" data-target="#menuDropdown" aria-controls="menuDropdown" aria-expanded="false" aria-label="Toggle navigation">
									<span></span>
									<span></span>
									<span></span>
								</button>
								{/* <!-- Author Nav ==== --> */}
								<div className="secondary-menu" style={{ display: "none" }}>
									<div className="secondary-inner">
										<ul>
											<li className="search-btn"><button id="quik-search-btn" type="button" className="btn-link"><i className="fa fa-search"></i></button></li>
										</ul>
									</div>
								</div>
								{/* <!-- Navigation Menu ==== --> */}
								<div className="menu-links navbar-collapse collapse justify-content-end" id="menuDropdown">
									<div className="menu-logo">
										<Link to={"/"}><img src={logo} alt="" /></Link>
									</div>
									<ul className="nav navbar-nav">





										<li className="active"><Link to={"/"}>{t('Anasayfa')}<i ></i></Link>
											{/* <ul className="sub-menu">
												<li><Link to={"/"}><span>Home Classic</span> </Link></li>
												<li><Link to={"index-2"}><span>Home Modern</span></Link></li>
												<li><Link to={"index-3"}><span>Home Simple</span></Link></li>
											</ul> */}
										</li>
										<li ><Link to={"/about-2"}>{t('Hakkımızda')}<i ></i></Link>

										</li>
										<li className="add-mega-menu"><Link to="/services-2">{t('Yatırım')}<i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu add-menu">
												<li className="add-menu-left">
													{
														currentLang && currentLang === "tr" ?
															data.map((item, i) => {
																return (

																	<li><Link to={"/services-2" + "/" + item.url}><span>{item.title}</span></Link></li>

																)
															}) :
															yatirimEN.map((item, i) => {
																return (
																	<li><Link to={"/services-2" + "/" + item.url}><span>{item.title}</span></Link></li>

																)
															})
													}
												</li>


												{/* <li><Link to="#">Pages</Link>
													<ul>
														<li><Link to={"about-1"}><span>About Us Simple</span></Link></li>
														<li><Link to={"about-2"}><span>About Us Classic</span></Link></li>
														<li><Link to={"about-3"}><span>About Us Modern</span></Link></li>
														<li><Link to={"event"}><span>Event</span></Link></li>
													</ul>
												</li> */}
												{/* <li><Link to="#">Pages</Link>
													<ul>
														<li><Link to={"services-1"}><span>Services Simple</span></Link></li>
														<li><Link to={"services-3"}><span>Services Modern</span></Link></li>
														<li><Link to={"services-details"}><span>Services Details</span></Link></li>
													</ul>
												</li> */}
												{/* <li><Link to="#">Pages</Link>
													<ul>
														<li><Link to={"company-history-1"}><span>Company History 1</span></Link></li>
														<li><Link to="faq-1"><span>FAQ's</span></Link></li>
														<li><Link to={"team"}><span>Team</span></Link></li>
													</ul>
												</li> */}
												{/* <li><Link to="#">Pages</Link>
													<ul>

														<li><Link to={"error-404"}><span>404 Page</span></Link></li>
													</ul>
												</li> */}
											</ul>
										</li>
										<li className="add-mega-menu"><Link to={"/campaigns"}>{t('Promosyonlar')}<i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu add-menu">
												<li className="add-menu-left">
													{/* <h5 className="menu-adv-title">Our Product</h5> */}
													<ul>
														{currentLang && currentLang === "en" ?
															kampanyaEN.map((item, i) => {
																return (
																	<li key={i}><a  href={"/campaigns" + "/" + item.url}><span>{item.title}</span></a></li>
																)

															}) :
															kampanya.map((item, i) => {
																return (
																	<li key={i}><Link to={"/campaigns" + "/" + item.url}><span>{item.title}</span></Link></li>
																)

															})
														}


														{/* <li><Link to={"shop"}><span>Shop</span> </Link></li>
														<li><Link to={"shop-sidebar"}><span>Shop Sidebar</span></Link></li>
														<li><Link to={"shop-cart"}><span>Cart</span></Link></li>
														<li><Link to={"shop-checkout"}><span>Checkout</span></Link></li>
														<li><Link to={"shop-details"}><span>Product Details</span></Link></li> */}
													</ul>
												</li>
												{/* <li className="add-menu-right">
													<img src={adv} alt="" />
												</li> */}
											</ul>
										</li>
										<li ><Link to={"/trading-platform"}>{t('İşlem Platformları')}<i ></i></Link></li>

										<li className="has-mega-menu "><Link to={"/company-history-2"}>{t('Nova Invest Avantajları')}</Link>
											{/* <li><Link to={"company-history-2"}><span>Company History 2</span></Link></li> */}

											{/* <ul className="mega-menu">
												<li><Link to={"#"}>Portfolio Grid</Link>
													<ul>
														<li><Link to={"portfolio-grid-2"}><span>Portfolio Grid 2</span></Link></li>
														<li><Link to={"portfolio-grid-4"}><span>Portfolio Grid 4</span></Link></li>
														<li><Link to={"portfolio-grid-wide"}><span>Portfolio Grid Wide</span></Link></li>
													</ul>
												</li>
												<li><Link to={"#"}>Portfolio Masonry</Link>
													<ul>
														<li><Link to={"portfolio-masonry-grid-2"}><span>Masonry Grid 2</span></Link></li>
														<li><Link to={"portfolio-masonry-grid-3"}><span>Masonry Grid 3</span></Link></li>
														<li><Link to={"portfolio-masonry-grid-4"}><span>Masonry Grid 4</span></Link></li>
														<li><Link to={"portfolio-masonry-grid-wide"}><span>Masonry Grid Wide</span></Link></li>
													</ul>
												</li>
												<li><Link to={"#"}>Portfolio Style</Link>
													<ul>
														<li><Link to={"portfolio-carousel-1"}><span>Portfolio Carousel 1</span></Link></li>
														<li><Link to={"portfolio-carousel-2"}><span>Portfolio Carousel 2</span></Link></li>
														<li><Link to={"portfolio-carousel-3"}><span>Portfolio Carousel 3</span></Link></li>
														<li><Link to={"portfolio-carousel-4"}><span>Portfolio Carousel 4</span></Link></li>
													</ul>
												</li>
												<li><Link to={"#"}>Portfolio Single</Link>
													<ul>
														<li><Link to={"portfolio-single-1"}><span>Portfolio Single 1</span></Link></li>
														<li><Link to={"portfolio-single-2"}><span>Portfolio Single 2</span></Link></li>
														<li><Link to={"portfolio-single-3"}><span>Portfolio Single 3</span></Link></li>
														<li><Link to={"portfolio-single-4"}><span>Portfolio Single 4</span></Link></li>
													</ul>
												</li>
											</ul> */}
										</li>
										<li className="add-mega-menu"><Link to={"#"}>{t('Hesap')}<i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu add-menu">
												<li className="add-menu-left">
													{/* <h5 className="menu-adv-title">Our Product</h5> */}
													<ul>
														<li ><a href={sca}><span>{t('Giriş Yap')}</span></a></li>
														<li ><a   href={"/sign-up"}><span>{t('Hesap Oluştur')}</span></a></li>

													</ul>
												</li>
											</ul>
										</li>
										<li className="active"><a href={"/contact-1"}>{t('İletişim')}<i ></i></a>

										</li>

										<li className="has-mega-menu select-menu ">
											<Select
												options={this.options}
												components={{ Option: CustomOption }}
												onChange={this.changeLanguageHandler}
												styles={{
													control: (styles) => ({
														...styles,
														marginTop: "15px",
														backgroundColor: '#ecf0f1',
														borderRadius: '10px',
														height: '40px',
														width: '100px',
														fontSize: '16px',
														border: '2px solid #AD66CF',
														cursor: 'pointer',
														color: "#AD66CF",
														fontWeight: "bold"
													}),
												}}
												defaultValue={currentLang == "en" ? this.options[0] : this.options[1] }
											/>
										</li>

										{/* <li><Link to={"#"}>Blog <i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu left">
												<li><Link to={"blog-standard"}><span>Blog Standard</span></Link></li>
												<li><Link to={"blog-classic"}><span>Blog Classic</span></Link></li>
												<li><Link to={"blog-classic-sidebar"}><span>Blog Classic Sidebar</span></Link></li>
												<li><Link to={"blog-list"}><span>Blog List Sidebar</span></Link></li>
												<li><Link to={"blog-masonry"}><span>Masonry</span></Link></li>
												<li><Link to={"blog-details-sidebar"}><span>Blog Details</span></Link></li>
											</ul>
										</li> */}
									</ul>
									<div className="navbar-footer">
										<ul className="nav-social-link">
											<li><a target="_blank" href="https://www.facebook.com/novainvestofc" rel="noreferrer" className="btn btn-primary"><i className="fa fa-facebook"></i></a></li>
											<li><a target="_blank" href="https://www.threads.net/@novainvestofc" rel="noreferrer" className="btn btn-primary"><img width="22" height="22" src="https://img.icons8.com/pulsar-line/32/ffffff/threads.png" alt="threads"/></a></li>
											<li><a target="_blank" href="https://www.instagram.com/novainvestofc" rel="noreferrer" className="btn btn-primary"><i className="fa fa-instagram"></i></a></li>
											<li><a target="_blank" href="https://x.com/novainvestofc" rel="noreferrer" className="btn btn-primary"><img width="20" height="20" src="https://img.icons8.com/ios/20/ffffff/twitterx--v2.png" alt="twitterx--v2"/></a></li>
										</ul>
										<p className="copyright-text">© 2022 Nova. {t('Tüm Hakları Saklıdır')}</p>
									</div>
									<div className="menu-close" id="menuClose">
										<i className="ti-close"></i>
									</div>
								</div>
								{/* <!-- Navigation Menu END ==== --> */}
							</div>
						</div>
					</Sticky>
					{/* <!-- Search Box ==== --> */}
					<div className="nav-search-bar" style={{ display: "none" }}>
						<form action="#">
							<input name="search" defaultValue="" type="text" className="form-control" placeholder="Type to search" />
							<span><i className="ti-search"></i></span>
						</form>
						<span id="search-remove"><i className="ti-close"></i></span>
					</div>
				</header>
				{/* <!-- Header Top END ==== --> */}
			</>
		);
	}
}

export default withTranslation()(Header);
