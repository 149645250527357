import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import ModalVideo from 'react-modal-video'


//importing translation file
import "../../../i18n"
import { withTranslation } from 'react-i18next';

// Layout
import Header from "../../layout/header/header1";
import Footer from "../../layout/footer/footer1";

// Elements
import Count from '../../elements/counter/counter-sensor';
import MainSlider from '../../elements/main-slider/slider1';
import About from '../../elements/about/about1';
import Team from '../../elements/team/team2';
import Testimonial from '../../elements/testimonial/testimonial1';
import BlogSlider from '../../elements/blog/blog-slider';
import ContactSidebar from '../../elements/contact-sidebar';
import PriceFlow from '../../elements/price-flow/PriceFlow';

// Images
import blogGridPic1 from '../../../images/blog/grid/hesap-olustur.png';
import aboutPic2 from '../../../images/about/about2.png';
import bgPic2 from '../../../images/background/yatırıma-yonlenecek.png';
import bgPic3 from '../../../images/background/testimonial.png';
import partnerLogo1 from '../../../images/partner/logo1.png';
import partnerLogo2 from '../../../images/partner/logo2.png';
import partnerLogo3 from '../../../images/partner/logo3.png';
import partnerLogo4 from '../../../images/partner/logo4.png';
import partnerLogo5 from '../../../images/partner/logo5.png';
import partnerLogo6 from '../../../images/partner/logo6.png';
import partnerLogo7 from '../../../images/partner/logo7.png';
import partnerLogo8 from '../../../images/partner/logo8.png';
import partnerLogo9 from '../../../images/partner/logo9.png';

class Index extends Component {

	constructor() {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	openModal() {
		this.setState({ isOpen: true })
	}

	render() {
		const { t } = this.props;
		return (
			<>


				<Header />

				<div className="page-content bg-white">

					<MainSlider />
					<PriceFlow />
					<ContactSidebar />

					<div className="content-block" id="content-area">

						<About />

						<div className="section-area section-sp3 ovpr-dark bg-fix about-media-bx parallax" style={{ backgroundImage: "url(" + bgPic2 + ")" }}>
							<div className="container">
								<div className="row">
									<div className="col-lg-6 wow fadeInUp m-md-b30" data-wow-delay="0.4s">
										<div className="video-media-bx">
											<img src={aboutPic2} alt="" />
											{/* <Link to={"#"} onClick={this.openModal} className="popup-youtube"><i className="fa fa-play"></i></Link> */}
										</div>
									</div>
									<div className="col-lg-6 wow fadeIn" data-wow-delay="0.8s">
										<div className="heading-bx text-white m-t20">
											<h2 className="m-b10">{t('Global Perspektif')}</h2>
											<h5 className="fw5 m-t15">{t('Nova Invest, yatırımcılarına küresel finans piyasalarındaki fırsatları değerlendirme imkanı sunar. Küresel ekonomik gelişmeleri yakından takip eden ve uluslararası piyasalardaki dinamiklere hakim olan analistlerimiz, yatırımcılarımıza dünya genelinde çeşitli yatırım fırsatları hakkında bilgi sağlar. Bu global perspektif, yatırımcılarımızın portföylerini çeşitlendirmelerine ve uluslararası piyasalardaki potansiyel getirilere erişmelerine olanak tanır.')}</h5>
											<a className="btn button-md radius-xl" href={"/services-2"}>{t('Global Yatırım')}</a>

										</div>
										{/* <div className="row">
											<div className="col-lg-4 col-md-4 col-sm-4 col-4">
												<div className="counter-style-1">
													<div className="text-secondry">
														<Count counter={25} />
														<span>K</span>
													</div>
													<span className="counter-text">Satisfied Clients</span>
												</div>
											</div>
											<div className="col-lg-4 col-md-4 col-sm-4 col-4">
												<div className="counter-style-1">
													<div className="text-secondry">
														<Count counter={35} />
														<span>+</span>
													</div>
													<span className="counter-text">Winning Awards</span>
												</div>
											</div>
											<div className="col-lg-4 col-md-4 col-sm-4 col-4">
												<div className="counter-style-1">
													<div className="text-secondry">
														<Count counter={180} />
														<span>+</span>
													</div>
													<span className="counter-text">Completed works</span>
												</div>
											</div>
										</div> */}
									</div>
								</div>
							</div>
						</div>

						{/* <div className="section-area bg-gray our-partner" data-name="PARTNER">
							<div className="container">
								<div className="row">
									<div className="col-lg-6 section-sp3">
										<div className="heading-bx">
											<h2 className="title-head m-b0">We Are Known <br/>By The Companies <br/>We Keep</h2>
											<p className="m-b0">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
										</div>
										<Link to={"/portfolio-grid-4"} className="btn button-md radius-xl">View Project</Link>
									</div>
									<div className="col-lg-6">
										<div className="our-partner-box">
											<div className="partner-logo"><Link to={"#"}><img src={partnerLogo1} alt=""/></Link></div>
											<div className="partner-logo"><Link to={"#"}><img src={partnerLogo2} alt=""/></Link></div>
											<div className="partner-logo"><Link to={"#"}><img src={partnerLogo3} alt=""/></Link></div>
											<div className="partner-logo"><Link to={"#"}><img src={partnerLogo4} alt=""/></Link></div>
											<div className="partner-logo"><Link to={"#"}><img src={partnerLogo5} alt=""/></Link></div>
											<div className="partner-logo"><Link to={"#"}><img src={partnerLogo6} alt=""/></Link></div>
											<div className="partner-logo"><Link to={"#"}><img src={partnerLogo7} alt=""/></Link></div>
											<div className="partner-logo"><Link to={"#"}><img src={partnerLogo8} alt=""/></Link></div>
											<div className="partner-logo"><Link to={"#"}><img src={partnerLogo9} alt=""/></Link></div>
										</div>
									</div>
								</div>


							</div>
						</div> */}

						{/* <div className="section-area section-sp1 bg-gray">
							<div className="container">
								<Team />
							</div>
						</div> */}
						<div style={{ textAlign: "left", display: "flex", justifyContent: "center", marginTop: "45px" }}>
							<div className="col-lg-8 service-textarea" >
								<h1 style={{ textAlign: "center", fontSize: "50px" }} className="title m-b10">{t('Neden')} Nova Invest?</h1>
								{/* <p>Standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p> */}
								<ul className="list-circle">
									<li>{t('Geniş Yatırım Olanakları:Nova Invest, müşterilerine forex, emtia, hisse senetleri ve kripto paralar gibi çeşitli finansal enstrümanlarda geniş bir yatırım yelpazesi sunar. Bu çeşitlilik, yatırımcılara farklı piyasa koşullarına adapte olma ve portföylerini çeşitlendirme imkanı sağlar.')}</li>
									<li>{t('Teknik Analiz Eğitimleri:Yatırımcıların piyasa trendlerini daha iyi anlamalarını ve etkili yatırım stratejileri oluşturmalarını desteklemek adına Nova Invest, teknik analiz eğitimleri sunar. Bu eğitimlerle müşteriler, grafikleri okuma ve piyasa hareketlerini daha iyi yorumlama becerilerini geliştirebilirler.')}</li>
									<li>{t('Global Perspektif:Londra merkezli olan Nova Invest, global finans piyasalarındaki fırsatları değerlendirme konusunda müşterilerine avantaj sunar. Uzman analistlerimiz, dünya genelindeki ekonomik gelişmeleri yakından takip eder ve müşterilere uluslararası piyasalardaki potansiyel getirilere erişim sağlar.')}</li>
									<li>{t('Müşteri Odaklı Hizmet Anlayışı:Nova Invest, müşteri memnuniyetini en üst düzeyde tutan bir anlayışa sahiptir. Her müşterinin ihtiyaçlarına özel çözümler sunarak, finansal hedeflerine ulaşmalarına destek olmayı amaçlar. Güvenilirlik, şeffaflık ve sürekli gelişim ilkeleriyle müşterilere güven verir ve onların finansal başarılarına ortak olur.')}</li>
								</ul>
							</div>
						</div>

						<div className="section-area section-sp2 overflow-hidden bg-fix ovprd-dark news-box parallax" style={{ backgroundImage: "url(" + bgPic3 + ")" }}>
							<div className="posi-rl zi1">
								<div className="heading-bx text-white">
									<h2 className="m-b10">{t('İşte Değerli Müşterilerimizin Hakkımızdaki Yorumları')}</h2>
								</div>

								<Testimonial />

							</div>
						</div>

						<div className="section-area section-sp2 overflow-hidden news-area" data-name="Nova Invest - Nova Invest - Nova Invest - Nova Invest - ">
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<div className="blog-post blog-md md-large">
											<div className="ttr-post-media">
												<Link to={"/blog-details-sidebar"}><img style={{ borderRadius: "15px" }} src={blogGridPic1} alt="" /></Link>
											</div>
											<div className="ttr-post-info">
												<h3 className="post-title"><Link to={"/blog-details-sidebar"}>{t('Hesap Oluşturun, Geleceğinizi Şekillendirin!')}</Link></h3>
												<ul className="media-post">
													{/* <li><Link to={"/blog-details-sidebar"}>By Jone Doe</Link></li>
													<li><Link to={"/blog-details-sidebar"}>12 March 2020</Link></li> */}
												</ul>
												<p>{t("Nova Invest ailesine katılmak için bir hesap oluşturun ve finansal hedeflerinize bir adım daha yaklaşın. Hesap oluşturmak ücretsiz, hızlı ve güvenlidir. Size özel yatırım çözümlerimiz ve geniş yatırım yelpazemizle, Nova Invest'de size uygun bir yer bulun.")}</p>
												<div className="post-extra">
													<Link to="/sign-up" className="btn button-md radius-xl">{t('Hesap Oluştur')}</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* <div className="posi-rl zi1">
								<BlogSlider />
							</div> */}
						</div>

					</div>
				</div>

				<Footer />

				{/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='xneM6b83KCA' onClose={() => this.setState({isOpen: false})} /> */}

			</>
		);
	}
}

export default withTranslation()(Index);