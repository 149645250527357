import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';
import Iframe from 'react-iframe';
import connector from '../../../data/connector';
// Layout
import Header from "../../layout/header/header1";
import Footer from "../../layout/footer/footer1";

// Images
import bannerPic1 from '../../../images/banner/iletisim.png';
import pattern1 from '../../../images/pattern/pt1.png';
import whatsapp from "../../../images/whatsapp.png"
import { useState } from 'react';
import { useEffect } from 'react';
import ReactInputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';
function Contact1() {
	const { t, i18n } = useTranslation();

	let errorMessage = `${t('Bir Hata Oluştu! Lütfen sayfayı yenileyip tekrar deneyin.')}`;
	let successMessage = `${t('İsteğiniz Alındı! Ekibimiz en kısa süre içerisinde sizinle iletişime geçecektir.')}`
	const [name, setName] = useState("");
	const [surname, setSurname] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [message, setMessage] = useState("");
	const [validator, setValidator] = useState(false);
	const [isNull, setIsNull] = useState(false);
	const [kvkkContact, setKvkkContact] = useState(false);
	const [isSend, setIsSend] = useState(false);
	const [alerts, setAlerts] = useState("")
	const [formUrl, setFormUrl] = useState()

	var data = {
		"firstName": name,
		"lastName": surname,
		"phone": String(phone).replace(" ", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", ""),
		"email": email,
		"message": message,
		"approvedConditions": kvkkContact
	}



	useEffect(() => {
		setFormUrl(`${'https://' + (window.location.hostname.replace('www.', '')) + "/api/v1/Submit?brand=2"} `)
		 //setFormUrl(`${'https://' + "nova.testmedici.com" + "/api/v1/Submit?brand=2"} `)
	})
	useEffect(() => {
		if (name === "" || email === "" || phone === "" || surname === "" || message === "" || kvkkContact === false || phone.includes("_") || validator === false) {
			setIsNull(false)
		}
		else {
			setIsNull(true)
		}
	}, [name, email, phone, surname, message, validator, kvkkContact])

	function EmailChange(e) {
		const validEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		let isOk = validEmail.test(String(e).toLowerCase())
		if (isOk) {
			setEmail(e);
			setValidator(true)
		}
		else {
			setEmail(e);
			setValidator(false)
		}
	}

	async function Submit() {
		setIsSend(true)
		connector(formUrl, data)
			.then(data => {
				if (data.success === true) {
					setAlerts(successMessage)
					setIsSend(false);
					// console.log(data);
				}
				else {
					setAlerts(errorMessage)

					setIsSend(false)
				}
			})
		// .catch(err => {
		//     console.log(err);
		// })
		setName("");
		setSurname("");
		setEmail("");
		setPhone("");
		setMessage("");
		setAlerts("")

	}
// console.log(connector);
	return (
		<>

			<Header />

			<div className="page-content bg-white">
				<div className="page-banner ovpr-dark overlay-dotted ovdt1 parallax" style={{ backgroundImage: "url(" + bannerPic1 + ")" }}>
					<div className="container">
						<div className="page-banner-entry">
							<h1 className="text-white">{t('Bizimle İletişime Geçin!')}</h1>
							<div className="breadcrumb-row">
								<ul className="list-inline">
									<li><Link to="/"><i className="fa fa-home"></i>{t('Anasayfa')}</Link></li>
									<li>{t('İletişim')}</li>
								</ul>
							</div>
						</div>
					</div>
					<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
				</div>
				<div className="content-block" id="content-area">

					<div className="section-area section-sp1" style={{ backgroundImage: "url(" + pattern1 + ")" }}>
						<div className="container">
							<div className="heading-bx m-sm-t20">
								<h2 className="title-head m-b0">{t('Bizimle İletişime Geçin!')}</h2>
							</div>
							<div className="row">
								<div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-delay="0.3s">
									<div className="icon-box">
										<h5 className="title"><i className="ti-map-alt"></i>{t('Adres')}</h5>
										<p>{t('22 Bishopsgate, London EC2N 4AJ Birleşik Krallık')}</p>
									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-delay="0.6s">
									<div className="icon-box">
										<h5 className="title"><i className="ti-id-badge"></i>{t('İletişim')}</h5>
										<a href={"mailto:support@nova-markets.com"}>support@nova-markets.com</a>
										<p><a href='tel:+447597058146'>+44 75970 58146</a></p>
										<div style={{ display: "flex", alignContent: "center", alignItems: "flex-start" }}>
											<img style={{ width: "25px", marginBottom: "-2px" }} src={whatsapp} />
											<p><a href='tel:+447597059422'>+44 75970 59422</a></p>
										</div>

									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-delay="0.9s">
										<div className="icon-box">
											<h5 className="title"><i className="ti-world "></i>Follow Us</h5>		
											<ul className="list-inline ft-social-bx">
												<li><a target='_blank' href="https://www.facebook.com/novainvestofc" className="btn button-sm"><i className="fa fa-facebook"></i></a></li>
												<li><a target='_blank' href="https://x.com/novainvestofc" className="btn button-sm"><img width="18" height="18" src="https://img.icons8.com/ios/32/ffffff/twitterx--v2.png" alt="twitterx--v2"/></a></li>
												<li><a target='_blank' href="https://www.instagram.com/novainvestofc" className="btn button-sm"><i className="fa fa-instagram"></i></a></li>
												<li><a target='_blank' href="https://www.threads.net/@novainvestofc" className="btn button-sm"><img width="22" height="22" src="https://img.icons8.com/pulsar-line/22/ffffff/threads.png" alt="threads"/></a></li>
											</ul>
										</div>
									</div>
							</div>
						</div>
					</div>
					{/* <div className="section-area">
							<Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3448.1298878182047!2d-81.38369578541523!3d30.204840081824198!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e437ac927a996b%3A0x799695b1a2b970ab!2sNona+Blue+Modern+Tavern!5e0!3m2!1sen!2sin!4v1548177305546" className="align-self-stretch map-frame d-flex"allowfullscreen></Iframe>
						</div> */}
					<div className="section-area section-sp2" style={{ backgroundImage: "url(" + pattern1 + ")" }}>
						<div className="container">
							<div className="row">
								<div className="col-lg-12 col-md-12 wow fadeIn" data-wow-delay="0.4s">
									<form className="contact-bx ajax-form">
										<div className="ajax-message"></div>
										<div className="heading-bx">
											<h2 className="title-head m-b0">{t('Sorularınızı Bize Danışabilirsiniz')}</h2>
										</div>
										<div className="row placeani">
											<div className="col-lg-4">
												<div className="form-group">
													<label>{t('İsim')}</label>
													<input
														type="text"
														name="name"
														placeholder={`${t('İsim')}`}
														className="form-control"
														value={name}
														onChange={event => { setName(event.target.value) }}
														required
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label>{t('Soyisim')}</label>
													<input
														type="text"
														name="name"
														placeholder={`${t('Soyisim')}`}
														className="form-control"
														value={surname}
														onChange={event => { setSurname(event.target.value) }}
														required
													/>
												</div>
											</div>
											<div className="form-group col-lg-4">
												<label>{t('E-Mail Adresi')}</label>
												<input
													type="email"
													name="email"
													placeholder={`${t('E-Mail Adresi')}`}
													className="form-control"
													value={email}
													onChange={event => { EmailChange(event.target.value) }}
													required
												/>
											</div>
											<div className="form-group">
												<label>{t('Telefon Numarası')}</label>
												<ReactInputMask
													mask="(999) 999 99 99"
													type="text" required
													className="form-control"
													value={phone}
													onChange={event => { setPhone(event.target.value) }}
													placeholder={`${t('Telefon Numarası')}`}
												/>
											</div>
											<div className="form-group">
												<label>{t('Mesajınız')}</label>
												<textarea
													name="text"
													cols="30"
													rows="6"
													placeholder={`${t('Mesajınız')}`}
													className="form-control"
													value={message}
													onChange={event => { setMessage(event.target.value) }}
													required
												/>
											</div>
											<div className="col-lg-12">
												<div className="form-group">
													{data.success === true ?
														<p style={{ color: "green" }}>{alerts}</p>
														:
														<p style={{ color: "green" }}>{alerts}</p>
													}
												</div>
											</div>
											<div className="col-12" style={{ textAlign: "left" }}>
												<input required type="checkbox" onClick={() => setKvkkContact(kvkkContact ? false : true)} />
												<span><a href='/kvkk' target='_blank' style={{ textDecoration: "underline" }}>{t('Kişisel Verilerin Korunması ve İşlenmesi Sözleşmesi')}</a></span>
											</div><br/><br/>
											<div className="col-5">
												<div className="send-btn">
													<div className="send-btn">
														{isNull ?
															<button type="submit" value="submit" onClick={() => { Submit() }} className="btn default-btn">{t('Gönder')}</button>
															:
															<button type="submit" value="submit" className="btn default-btn">{isSend ? `${t('Gönderiliyor')}` : `${t('Gönder')}`}</button>
														}
													</div>
												</div>
											</div>
											
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>

			<Footer />

		</>
	);

}

export default Contact1;