
import PortImg1 from '../images/portfolio/portfolio-1/sigorta.png';
import PortImg2 from '../images/portfolio/portfolio-1/döviz-kuru-sabitleme.png';
import PortImg3 from '../images/portfolio/portfolio-1/takvim.png';
import PortImg4 from '../images/portfolio/portfolio-1/vip.jpeg';
import PortImg5 from '../images/portfolio/portfolio-1/arkadasini-getir.png';
import PortImg6 from '../images/portfolio/portfolio-1/yatırım-destekcisi.png';
import PortImg7 from '../images/portfolio/portfolio-1/demo-hesap.png';
import miniimg1 from "../images/portfolio/portfolio-1/mini-arkadasını-getir.png"


const kampanyaEN = [
    {
        id: 1,
        img: PortImg1,
        url: "insured-transaction-bonus",
        title: "Insured Transaction Bonus",
        description: "Feeling secure and having flexibility are crucial when investing in today's financial markets. With our specially designed 'Insured Transaction Bonus,' we aim to safeguard and elevate your investments. This innovative campaign is designed to provide our investors with a secure environment.",
        catchword: "WE INSURE 20% OF YOUR INVESTMENTS STARTING FROM $400 AS NOVA INVEST!",
        campaignDetail: "Firstly, by insuring a portion of the fund amounts of our investment partners, we offer our investors the chance to compensate for potential losses.",
        example: "For example, with a 20% insurance on your initial funding amount of $400, we create a $80 guarantee in your account. We are creating an account volume to compensate for possible losses.",
        programAdvantageTitle1: "",
        programAdvantage1: "",
        programAdvantageTitle2: "",
        programAdvantage2: "",
        programAdvantageTitle3: "",
        programAdvantage3: "",
        programAdvantageTitle4: "",
        programAdvantage4: "",
        programAdvantageTitle5: "",
        programAdvantage5: "",
    },
    {
        id: 2,
        img: PortImg2,
        url: "campaign-to-fix-the-exchange-rate",
        title: "Exchange Rate Fixing Campaign",
        description: "In today's global financial markets, currency exchange rates tend to fluctuate, creating uncertainty for investors. Our 'Exchange Rate Fixing Campaign' aims to address this by offering investors the opportunity to trade at a fixed exchange rate, minimizing the impact of volatile currency fluctuations.",
        catchword: "",
        campaignDetail: "The Exchange Rate Fixing Campaign offers investors the opportunity to trade at an advantageous exchange rate.",
        example: "For example, during weeks of economic decisions, you can make your initial funding at an advantageous exchange rate, allowing our investors to minimize uncertainties arising from currency fluctuations and manage their transactions more securely.",
        programAdvantageTitle1: "",
        programAdvantage1: "",
        programAdvantageTitle2: "",
        programAdvantage2: "",
        programAdvantageTitle3: "",
        programAdvantage3: "",
        programAdvantageTitle4: "",
        programAdvantage4: "",
        programAdvantageTitle5: "",
        programAdvantage5: ""
    },
    {
        id: 3,
        img: PortImg3,
        url: "invest-week-bonus",
        title: "Investment Week Bonus",
        description: "Investing in financial markets is now more exciting than ever! With our special 'Investment Weeks,' we offer investors an extraordinary experience. This campaign aims to reward successful users and increase investment competition. Participating in this competition is open to everyone, providing a chance for investors to both win and have fun.",
        catchword: "",
        campaignDetail: "Participating in the 'Investment Week Bonus' is very easy! Do your best to be successful in any market week. At the end of the investment week, by achieving the highest return, you can win surprise rewards.",
        example: "",
        programAdvantageTitle1: "",
        programAdvantage1: "",
        programAdvantageTitle2: "",
        programAdvantage2: "",
        programAdvantageTitle3: "",
        programAdvantage3: "",
        programAdvantageTitle4: "",
        programAdvantage4: "",
        programAdvantageTitle5: "",
        programAdvantage5: ""
    },
    {
        id: 4,
        img: PortImg4,
        url: "vip-bonus",
        title: "VIP Program",
        description: "Would you like to step into a privileged world while building your investment portfolio? Your dream comes true with our VIP Program, designed especially for users with a monthly trading volume of 2500 USD. This program offers a campaign filled with privileges and advantages to lead you to financial success.",
        atchword: "",
        campaignDetail: "To participate in the 'Investment Week Bonus,' it is very easy! Do your best to be successful in any market week. At the end of the investment week, by achieving the highest return, you can win surprise rewards.",
        programAdvantageTitle1: "VIP Newsletters and Trades",
        programAdvantage1: "Catch the advantage of evaluating market trends with newsletters specially prepared for VIP Program members and the trades of our expert analysts. Receive VIP newsletters and trades to make the most accurate investment actions.",
        programAdvantageTitle2: "Monthly Analysis Reports",
        programAdvantage2: "To navigate financial markets accurately and safely, monthly analysis reports will guide you. With detailed analysis reports prepared exclusively for VIP Program members, you can instantly follow market developments and update your strategies accordingly.",
        programAdvantageTitle3: "One-on-One Expert Support",
        programAdvantage3: "Assigned one-on-one expert support exclusively for VIP Program members will help you manage your investments more effectively. With our expert support, you can create strategies and grow your portfolio optimally.",
        programAdvantageTitle4: "Exclusive Events and Seminars",
        programAdvantage4: "Participate in events and seminars specially organized for VIP Program members to closely monitor developments in financial markets. In these events, you can also have the opportunity to meet industry leaders.",
        programAdvantageTitle5: "VIP Investor Support",
        programAdvantage5: "With VIP investor support exclusively allocated to VIP Program members, you can obtain quick and effective solutions to all your questions. With a dedicated support team, you will have assistance at all times and find instant solutions to potential issues."
    },
    {
        id: 5,
        url: "bring-your-friend-bonus",
        img: PortImg5,
        title: "Bring Your Friend",
        description: "It's time to share your investments and celebrate success together! Share the doors of gain in the investment world with your friends and seize the chance to earn special bonuses with our 'Bring Your Friend, Claim Bonuses!' campaign.",
        catchword: "",
        campaignDetail: "",
        example: "",
        programAdvantageTitle1: "",
        programAdvantage1: "",
        programAdvantageTitle2: "",
        programAdvantage2: "",
        programAdvantageTitle3: "",
        programAdvantage3: "",
        programAdvantageTitle4: "",
        programAdvantage4: "",
        programAdvantageTitle5: "",
        programAdvantage5: ""

    },
    {
        id: 6,
        img: PortImg6,
        url: "investment-supporter-awards",
        title: "Investment Supporter Awards",
        description: "It's time to become the social star of the investment world! By actively promoting our platform on social media or participating in specific events, you can earn special rewards. We offer surprise bonuses and VIP benefits to users with the highest interactions.",
        programAdvantageTitle1: "Be Active on Social Media",
        programAdvantage1: "Attract attention by actively promoting our platform on social media. Share your experiences in the investment world and interact with other users.",
        programAdvantageTitle2: "Participate in Specific Events",
        programAdvantage2: "Contribute to our community by participating in designated events. Share your experiences and become the owner of special rewards by attending educational seminars, live broadcasts, or exclusive events.",
        programAdvantageTitle3: "",
        programAdvantage3: "",
        programAdvantageTitle4: "",
        programAdvantage4: "",
        programAdvantageTitle5: "",
        programAdvantage5: ""
    },
    {
        id: 7,
        img: PortImg7,
        url: "demo-account-competition",
        title: "Demo Account Competition",
        description: "Welcome to the investment world! Successfully utilizing our demo account and reaching a specific volume unlocks a special opportunity. It's time to solidify your success in the demo account by transitioning to a real account! Here are the details of this transition filled with exclusive advantages.",
        campaignDetail: "The Demo Account Competition offers a unique opportunity to users who have successfully utilized our demo account and reached a specific trading volume. This transition from demo to real accounts comes with special advantages.",
        catchword: "",
        example: "",
        programAdvantageTitle1: "Be Active on Social Media",
        programAdvantage1: "Gain attention by actively promoting our platform on social media. Share your experiences in the investment world and interact with other users.",
        programAdvantageTitle2: "Participate in Specific Events",
        programAdvantage2: "Contribute to our community by participating in designated events. Share your experiences and become the owner of special rewards by attending educational seminars, live broadcasts, or exclusive events.",
        programAdvantageTitle3: "",
        programAdvantage3: "",
        programAdvantageTitle4: "",
        programAdvantage4: "",
        programAdvantageTitle5: "",
        programAdvantage5: ""
    }
]

export default kampanyaEN;