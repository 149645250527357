import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video'

// Images
import pattern1 from '../../../images/pattern/pt1.png';
import aboutPic1 from '../../../images/about/hakkımızda.png';
import Count from '../../elements/counter/counter-sensor';
import { withTranslation } from 'react-i18next';

class About1 extends Component{
	
	constructor () {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	openModal () {
		this.setState({isOpen: true})
	}
	
	render(){
		const { t } =this.props;

		return(
			<>
				<div className="section-area section-sp2 parallax" style={{backgroundImage: "url("+pattern1+")"}}>
					<div className="container">
						<div className="row">
							<div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
								<div className="heading-bx">
									<h2 className="title-head">{t('Şirketimiz Hakkında')}</h2>
									<p>{t('Nova Invest, 2008 yılından beri sermaye piyasaları alanında faaliyet gösteren Nova Invest 2020 yılında Türkiye pazarında hizmet vermeye başlamıştır.Güçlü özsermayesi ve uzman kadrosunu teknolojik alt yapısıyla birleştiren Nova Invest İngiltere ve Hollanda şubeleriyle hizmet vermektedir. Misyonumuz, yatırımcılarımıza dünya genelinde çeşitli finansal enstrümanlarda geniş bir yatırım yelpazesi sunarak, onların finansal hedeflerine ulaşmalarına katkıda bulunmak ve aynı zamanda finansal okuryazarlık düzeylerini artırmaktır. Vizyonumuz, güvenilirlik, şeffaflık ve sürekli gelişim ilkeleriyle şekillenir. Nova Invest olarak, küresel perspektifimizle uluslararası finans piyasalarındaki dinamikleri yakından takip ediyor ve müşterilerimize dünya genelindeki yatırım fırsatlarına erişim sunuyoruz.')}</p>
									<h6>{t('Temel Değerlerimiz:')}</h6>
								</div>
								<div className="feature-container left feature-bx1">
									<div className="feature-lg text-white m-b20">
										<Link to={"#"} className="icon-cell"><i style={{color:"#232eff69"}} className="flaticon-presentation"></i></Link> 
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">{t('Müşteri Odaklılık')}</h5>
										<p>{t('Yatırımcılarımızın ihtiyaçlarına odaklanarak, onların finansal başarılarına ortak olmak en önemli önceliğimizdir.')}</p>
									</div>
								</div>
								<div className="feature-container left feature-bx1 active">
									<div className="feature-lg text-white m-b20">
										<Link to={"#"} className="icon-cell"><i style={{color:"#232eff69"}} className="flaticon-presentation"></i></Link> 
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">{t('Güvenilirlik ve Şeffaflık')}</h5>
										<p>{t('Nova Invest olarak güvenilirlik ilkesini benimsemekte ve yatırımcılarımıza şeffaf bir hizmet sunmaktayız. İşlemlerimizde açıklık prensibine dayanarak, yatırımcılarımızın bize tam anlamıyla güvenmelerini sağlıyoruz.')}</p>
									</div>
								</div>
								<div className="feature-container left feature-bx1 active">
									<div className="feature-lg text-white m-b20">
										<Link to={"#"} className="icon-cell"><i style={{color:"#232eff69"}} className="flaticon-presentation"></i></Link> 
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">{t('Sürekli Gelişim')}</h5>
										<p>{t('Finans piyasalarındaki hızlı değişimlere ayak uydurabilmek ve yatırımcılarımıza her zaman en güncel bilgileri ve hizmetleri sunabilmek adına sürekli olarak kendimizi güncelliyoruz.')}</p>
									</div>
								</div>
								{/* <Link to={"portfolio-grid-3"} className="btn button-md radius-xl">View Project</Link> */}
							</div>
							<div className="col-lg-6 wow fadeInUp" data-wow-delay="0.4s">
								<div className="about-imgbox">
									<img src={aboutPic1} alt=""/>
									{/* <div className="about-year">
										<h2><Count counter={20}/>+</h2>
										<h5>Year In industry</h5>
										<Link to={"#"} onClick={this.openModal} className="popup-youtube videoplay-bx"><i className="fa fa-play"></i> <span>Play Video</span></Link>
										<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='xneM6b83KCA' onClose={() => this.setState({isOpen: false})} />
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation() (About1);