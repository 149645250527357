import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import kampanya from '../../../data/kampanya';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import kampanyaEN from '../../../data/kampanyaEN';
function Skillbar() {
	// constructor(props) {
	// 	super(props);

	// 	this.state = {
	// 	  urls: [],
	// 	};
	//   }
	//   componentDidMount(){
	// 	kampanya.forEach((obj) => {
	// 		this.setState((prevState) => ({
	// 		  urls: [...prevState.urls, obj.url],
	// 		}));
	// 	  });
	//   }

	// const { urls } = this.state;
	// console.log(urls);
	const { t, i18n } = useTranslation();
	const currentLang =i18n.language
	let location = useLocation();
	let detailPageName = location.pathname.split("/")[2];
	console.log(currentLang);
	let myObj;
	if (currentLang ==="tr"){
		kampanya.forEach(element => {
			// console.log(element.url);
			if (element.url === detailPageName) {
				myObj = element
			}
	
		});
	} else if (currentLang ==="en") {
		kampanyaEN.forEach(element => {
			// console.log(element.url);
			if (element.url === detailPageName) {
				myObj = element
			}
	
		});
	}
	
	

	// console.log(myObj);

	return (
		<>
			<div className="section-area section-sp1 bg-white">
				<div className="container">
					<div className="row">
					
										<div className="col-lg-6">
											<div className="heading-bx">
												<h2 className="title-head">{myObj.title}</h2>
												{/* <p className="m-b20">{item.description}</p> */}
												<p>
													{myObj.description}
												</p>
												<p>
													{myObj.campaignDetail}
												</p>
												<p>
													{myObj.example}
												</p>
												<em>
													<p style={{ fontWeight: "bold", color: "blue" }}>
														{myObj.catchword}
													</p>
												</em>


											</div>
										</div>
										<div className="col-lg-6">
											<img src={myObj.img} />
										</div>

										<div className="col-lg-6">
											{ myObj.programAdvantage1 || myObj.programAdvantage2 || myObj.programAdvantage3 || myObj.programAdvantage3 || myObj.programAdvantage4 || myObj.programAdvantage5 ?

												<h3>{t('Kampanya Avantajları')} </h3> :""
												}
											<div>
											<h6>{myObj.programAdvantageTitle1}</h6>
											<p>{myObj.programAdvantage1}</p>
											</div>
											<div>
											<h6>{myObj.programAdvantageTitle2}</h6>
											<p>{myObj.programAdvantage2}</p>
											</div>
											<div>
											<h6>{myObj.programAdvantageTitle3}</h6>
											<p>{myObj.programAdvantage3}</p>
											</div>
										</div>
										<div  className="col-lg-6">
										<div>
											<h6>{myObj.programAdvantageTitle4}</h6>
											<p>{myObj.programAdvantage4}</p>
											</div>
											<div>
											<h6>{myObj.programAdvantageTitle5}</h6>
											<p>{myObj.programAdvantage5}</p>
											</div>
										</div>
					

					</div>
				</div>
			</div>
		</>
	);

}

export default Skillbar;