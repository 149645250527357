import React, { useState, useEffect } from 'react'
import Slider from "react-slick";

const PriceFlow = () => {
    const [resultData, setResultData] = useState([]);

    const settings = {
        dots: false,
        arrow: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 3000,
        cssEase: "linear",
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
        ]
    };

    const renderCryptoImage = (asset) => {
        switch (asset) {
            case "EUR/USD":
                return (
                    <div className='position-relative' style={{ width: "35px", height: "35px" }}>
                        <img width="35" height="35" src="https://img.icons8.com/papercut/35/euro-pound-exchange.png" alt="euro-pound-exchange" style={{ position: "absolute", left: "-8px", top: "-5px" }} />
                        <img width="35" height="35" src="https://img.icons8.com/papercut/35/us-dollar.png" alt="us-dollar" style={{ position: "absolute", right: "-8px", top: "5px" }} />
                    </div>
                );
            case "USD/JPY":
                return (
                    <div className='position-relative' style={{ width: "35px", height: "35px" }}>
                        <img width="35" height="35" src="https://img.icons8.com/papercut/35/us-dollar.png" alt="euro-pound-exchange" style={{ position: "absolute", left: "-8px", top: "-5px" }} />
                        <img width="30" height="30" src="https://www.svgrepo.com/show/367063/jpy.svg" alt="us-dollar" style={{ position: "absolute", right: "-8px", top: "5px" }} />
                    </div>
                );
            case "GBP/USD":
                return (
                    <div className='position-relative' style={{ width: "35px", height: "35px" }}>
                        <img width="35" height="35" src="https://img.icons8.com/papercut/35/us-dollar.png" alt="euro-pound-exchange" style={{ position: "absolute", left: "-8px", top: "-5px" }} />
                        <img width="32" height="32" src="https://img.icons8.com/ios-filled/50/5C7CFA/british-pound.png" alt="us-dollar" style={{ position: "absolute", right: "-8px", top: "5px" }} />
                    </div>
                );
            case "AUD/USD":
                return (
                    <div className='position-relative' style={{ width: "35px", height: "35px" }}>
                        <img width="35" height="35" src="https://img.icons8.com/papercut/35/us-dollar.png" alt="euro-pound-exchange" style={{ position: "absolute", left: "-8px", top: "-5px" }} />
                        <img width="30" height="30" src="https://cdn-icons-png.flaticon.com/512/3573/3573641.png" alt="us-dollar" style={{ position: "absolute", right: "-8px", top: "5px" }} />
                    </div>
                );
            case "USD/CAD":
                return (
                    <div className='position-relative' style={{ width: "35px", height: "35px" }}>
                        <img width="35" height="35" src="https://img.icons8.com/papercut/35/us-dollar.png" alt="euro-pound-exchange" style={{ position: "absolute", left: "-8px", top: "-5px" }} />
                        <img width="38" height="38" src="https://img.icons8.com/color/38/canadian-dollar.png" alt="us-dollar" style={{ position: "absolute", right: "-8px", top: "5px" }} />
                    </div>
                );
            case "USD/CHF":
                return (
                    <div className='position-relative' style={{ width: "35px", height: "35px" }}>
                        <img width="35" height="35" src="https://img.icons8.com/papercut/35/us-dollar.png" alt="euro-pound-exchange" style={{ position: "absolute", left: "-8px", top: "-5px" }} />
                        <img width="35" height="35" src="https://img.icons8.com/color/35/swiss-franc--v1.png" alt="us-dollar" style={{ position: "absolute", right: "-8px", top: "5px" }} />
                    </div>
                );
            case "NZD/USD":
                return (
                    <div className='position-relative' style={{ width: "35px", height: "35px" }}>
                        <img width="35" height="35" src="https://img.icons8.com/papercut/35/us-dollar.png" alt="euro-pound-exchange" style={{ position: "absolute", left: "-8px", top: "-5px" }} />
                        <img width="35" height="35" src="https://cdn-icons-png.freepik.com/512/14756/14756610.png" alt="us-dollar" style={{ position: "absolute", right: "-8px", top: "5px" }} />
                    </div>
                );
            case "EUR/JPY":
                return (
                    <div className='position-relative' style={{ width: "35px", height: "35px" }}>
                        <img width="35" height="35" src="https://img.icons8.com/papercut/35/euro-pound-exchange.png" alt="euro-pound-exchange" style={{ position: "absolute", left: "-8px", top: "-5px" }} />
                        <img width="30" height="30" src="https://www.svgrepo.com/show/367063/jpy.svg" alt="us-dollar" style={{ position: "absolute", right: "-8px", top: "5px" }} />
                    </div>
                );
            case "GBP/JPY":
                return (
                    <div className='position-relative' style={{ width: "35px", height: "35px" }}>
                        <img width="32" height="32" src="https://img.icons8.com/ios-filled/50/5C7CFA/british-pound.png" alt="us-dollar" style={{ position: "absolute", left: "-8px", top: "-5px" }} />
                        <img width="30" height="30" src="https://www.svgrepo.com/show/367063/jpy.svg" alt="us-dollar" style={{ position: "absolute", right: "-8px", top: "5px" }} />
                    </div>
                );
            case "EUR/GBP":
                return (
                    <div className='position-relative' style={{ width: "35px", height: "35px" }}>
                        <img width="35" height="35" src="https://img.icons8.com/papercut/35/euro-pound-exchange.png" alt="euro-pound-exchange" style={{ position: "absolute", left: "-8px", top: "-5px" }} />
                        <img width="32" height="32" src="https://img.icons8.com/ios-filled/50/5C7CFA/british-pound.png" alt="us-dollar" style={{ position: "absolute", right: "-8px", top: "5px" }} />
                    </div>
                );
            default:
                return null;
        }
    };

    const getData = async () => {
        try {
            const response = await fetch('https://nova-markets.com/api/v1/Price/GetPrices?symbols=EUR%2FUSD%2CUSD%2FJPY%2CGBP%2FUSD%2CAUD%2FUSD%2CUSD%2FCAD%2CUSD%2FCHF%2CNZD%2FUSD%2CEUR%2FJPY%2CGBP%2FJPY%2CEUR%2FGBP')
            if (response.ok) {
                const data = await response.json();
                setResultData(data)
                console.log(resultData)
            } else {
                console.log("Network response was not ok.")
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getData()
    }, [])
    return (
        <>
            {/* <Slider {...settings}>
                {
                    resultData.map((e, index) => (
                        <div className='crypto-item' key={index}>
                            <div className='d-flex align-items-start'>
                                <div className="mt-1">
                                    {renderCryptoImage(e.s)}
                                </div>
                                <div className='ml-lg-4 ml-3'>
                                    <div style={{ fontWeight: 500 }}>
                                        {e.s}
                                    </div>
                                    <div>
                                        <div>
                                            <span className='text-success' style={{ fontWeight: 500 }}>{e.b}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </Slider> */}
            <iframe src="https://fxpricing.com/fx-widget/ticker-tape-widget.php?id=1,2,3,5,20,39,13,4&border=show&speed=50&click_target=blank&theme=transparent&tm-cr=212529&hr-cr=FFFFFF13&by-cr=28A745&sl-cr=DC3545&flags=circle&d_mode=compact-name&column=ask,bid,spread,chg_per&lang=en&font=Arial, sans-serif" width="100%" height={85} style={{ border: 'unset' }} /><style type="text/css" dangerouslySetInnerHTML={{ __html: "#fx-pricing-widget-copyright{text-align: center; font-size: 13px; font-family: sans-serif; margin-top: 10px; margin-bottom: 10px; color: #9DB2BD;} #fx-pricing-widget-copyright a{text-decoration: unset; color: #BB3534; font-weight: 600;}" }} />
        </>
    )
}

export default PriceFlow;