import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { withTranslation } from 'react-i18next';

// Images
import sliderImg1 from "../../../images/slider/banner1.webp"
import sliderImg2 from "../../../images/slider/banner2.webp"

class Slider1 extends Component{
	
	render(){
		const { t } =this.props;
		
		const settings = {
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
		};
		
		return(
			<>
				
				<Slider {...settings} className="tt-slider slider-one owl-btn-1 slider-sp0">
					<div className="slider-item">
						<div className="slider-thumb ovbl-middle">
							<img src={sliderImg1} alt=""/>
						</div>
						<div className="slider-content text-white">
							<div className="container">
								<div className="content-inner">
									<h6 className="sub-title">{t('Nova Invest')}</h6>
									<h2 className="title">{t('Geleceğinizi  Şekillendirecek Çözümler, Güçlü Bir Yatırımın Anahtarı!')}<br/></h2>
									<Link className="btn button-md radius-xl" to={"campaigns"}>{t('Promosyonlar')}</Link>
								</div>
							</div>
						</div>
					</div>			
					<div className="slider-item">
						<div className="slider-thumb ovbl-middle">
							<img src={sliderImg2} alt=""/>
						</div>
						<div className="slider-content text-white">
							<div className="container">
								<div className="content-inner">
									<h6 className="sub-title">{t('Globale Açılan Kapınız:')}</h6>
									<h2 className="title">{t('Nova Invest ile Yatırımınızın Yıldızı Parlasın!')}</h2>
									<Link className="btn button-md radius-xl" to={"services-2"}>{t('Global Yatırım')}</Link>
								</div>
							</div>
						</div>
					</div>					
				</Slider>
			</>
		);
	}
}

export default withTranslation()(Slider1);
