import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';
import { withTranslation } from 'react-i18next';

// Layout
import Header from "../../layout/header/header1";
import Footer from "../../layout/footer/footer1";

// Elements
import ClientLogo from "../../elements/client-logo/logo-carousel";

// Images
import bannerPic1 from '../../../images/banner/1920x766-promosyonlar-1.png';
import pattern1 from '../../../images/pattern/1.png';
import pattern2 from '../../../images/pattern/2.png';
import pattern3 from '../../../images/pattern/3.png';
import pattern4 from '../../../images/pattern/4.png';
import pattern5 from '../../../images/pattern/5.png';
import pattern6 from '../../../images/pattern/6.png';
import pattern7 from '../../../images/pattern/7.png';
// import aboutPic10 from '../../../images/about/pic10.jpg';
import pattern100 from '../../../images/pattern/pt1.png';


class CompanyHistory2 extends Component {

	render() {
		const { t } =this.props;

		return (
			<>

				<Header />

				<div className="page-content bg-white">
					<div className="page-banner ovpr-dark overlay-dotted ovdt2 parallax" style={{ backgroundImage: "url(" + bannerPic1 + ")" }}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">{t('Neden Nova Invest?')}</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i>{t('Anasayfa')}</Link></li>
										<li>{t('Neden Nova Invest?')}</li>
									</ul>
								</div>
							</div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
					</div>
					<div className="content-block" id="content-area">

						<div className="section-area section-sp1 bg-gray" style={{backgroundImage: "url("+pattern100+")"}}>
							<div className="container">
								<div className="heading-bx text-center">
									<h2 className="title-head m-b0">{t('Yatırımcılarımızın ihtiyaçlarına odaklanarak, onların finansal başarılarına ortak olmak en önemli önceliğimizdir.')} </h2>
									<p className="m-b0">{t('Vizyonumuz, güvenilirlik, şeffaflık ve sürekli gelişim ilkeleriyle şekillenir.')} </p>
								</div>
								<div className="timeline-view">
									<div className="timeline-area left wow fadeInUp" data-wow-delay="0.2s">
										<div className="timeline-content">
											{/* <h6 className="m-b5 text-primary">2017</h6> */}
											<h3 className="m-b10">{t('Deneyimli Ekibimiz')}</h3>
											<div className="m-b20"><img src={pattern1} className="radius-sm" alt="" /></div>
											<p>{t('Nova Invest, finans dünyasında uzun yıllara dayanan deneyime sahip bir ekip ile ilerlemektedir. Her bir üye, sektördeki güncel gelişmeleri yakından takip ederek yatırımcılara en iyi hizmeti sunma konusunda uzmandır.')}</p>
										</div>
									</div>
									<div className="timeline-area right wow fadeInUp" data-wow-delay="0.4s">
										<div className="timeline-content">
											{/* <h6 className="m-b5 text-primary">2016</h6> */}
											<h3 className="m-b10">{t('Yatırımcı Odaklı Yaklaşım')}</h3>
											<div className="m-b20"><img src={pattern2} className="radius-sm" alt="" /></div>
											<p>{t('Yatırımcı memnuniyetini en üst düzeyde tutmayı amaçlıyoruz. Nova Invest olarak, her yatırımcımızın ihtiyaçlarını anlamak ve onlara özel çözümler sunmak için çaba harcıyoruz.')}</p>
										</div>
									</div>
									<div className="timeline-area left wow fadeInUp" data-wow-delay="0.6s">
										<div className="timeline-content">
											{/* <h6 className="m-b5 text-primary">2015</h6> */}
											<h3 className="m-b10">{t('Güvenilirlik ve Şeffaflık')}</h3>
											<div className="row sp10">
												<div className="m-b20"><img src={pattern3} className="radius-sm" alt="" /></div>
												{/* <div className="m-b20 col-md-6"><img src={aboutPic7} className="radius-sm" alt="" /></div> */}
											</div>
											<p>{t('Nova Invest, güvenilirlik ilkesini benimsemekte ve yatırımcılara şeffaf bir hizmet sunmaktadır. İşlemlerimizde açıklık prensibine dayanarak, yatırımcılaramızın bize tam anlamıyla güvenmelerini sağlıyoruz.')}</p>
										</div>
									</div>
									<div className="timeline-area right wow fadeInUp" data-wow-delay="0.8s">
										<div className="timeline-content">
											{/* <h3 className="m-b5 text-primary">2012</h3> */}
											<h3 className="m-b10">{t('İnovasyon ve Teknoloji')}</h3>

											<div className="row sp10">
												<div className="m-b20"><img src={pattern4} className="radius-sm" alt="" /></div>
												{/* <div className="m-b20 col-md-6"><img src={aboutPic10} className="radius-sm" alt="" /></div> */}
											</div>
											<p>{t("Sektördeki en son teknolojik gelişmeleri takip ediyoruz ve bu teknolojileri kullanarak yatırımcılarımıza daha etkili çözümler sunuyoruz. İnovasyon, Nova Invest'ın temel prensiplerinden biridir.")}</p>
										</div>
									</div>
									<div className="timeline-area left wow fadeInUp" data-wow-delay="0.2s">
										<div className="timeline-content">
											{/* <h3 className="m-b5 text-primary">2011</h3> */}
											<h3 className="m-b10">
												{t('Kişiselleştirilmiş Yatırım Stratejileri')}
											</h3>

											<div className="row sp10">
												<div className="m-b20 col-md-12"><img src={pattern5} className="radius-sm" alt="" /></div>
											</div>
											<p>{t('Her yatırımcının finansal hedefleri farklıdır. Nova Invest, yatırımcılarının özel ihtiyaçlarını anlayarak kişiselleştirilmiş yatırım stratejileri oluşturur ve bu stratejilerle yatırımcıların başarıya ulaşmasına katkıda bulunur.')}</p>
										</div>
									</div>
									<div className="timeline-area right wow fadeInUp" data-wow-delay="0.4s">
										<div className="timeline-content">
											{/* <h3 className="m-b5 text-primary">2010</h3> */}
											<h3 className="m-b10">{t('Sosyal Sorumluluk')}</h3>
											<div className="row sp10">
												<div className="m-b20 col-md-12"><img src={pattern6} className="radius-sm" alt="" /></div>
											</div>
											<p>{t('Nova Invest, sadece finansal başarıya odaklanmaz, aynı zamanda toplumsal sorumluluk bilinciyle hareket eder. Sosyal sorumluluk projelerine aktif olarak katılır ve toplum için pozitif bir etki yaratmaya çalışır.')}</p>
										</div>
									</div>
									<div className="timeline-area left wow fadeInUp" data-wow-delay="0.2s">
										<div className="timeline-content">
											<h3 className="m-b10">{t('Sürekli Gelişim')}
											</h3>

											<div className="row sp10">
												<div className="m-b20 col-md-12"><img src={pattern7} className="radius-sm" alt="" /></div>
											</div>
											<p>{t('Nova Invest olarak, sürekli olarak kendimizi geliştirme çabası içindeyiz. Sektördeki değişimlere hızla adapte oluyor ve müşterilerimize her zaman en güncel bilgileri ve hizmetleri sunmaya özen gösteriyoruz.')}</p>
										</div>
									</div>
								</div>

							</div>
						</div>
						{/* 
						<div className="section-area section-sp2" style={{ backgroundImage: "url(" + pattern1 + ")" }}>
							<div className="container">
								<div className="heading-bx text-center">
									<h2 className="title-head m-b0">We Are Known By The <br />Companies We Keep</h2>
									<p className="m-b0">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
								</div>
								<ClientLogo />
							</div>
						</div> */}

					</div>
				</div>

				<Footer />

			</>
		);
	}
}

export default withTranslation() (CompanyHistory2);