import PortImg1 from '../images/portfolio/portfolio-1/sigorta.png';
import PortImg2 from '../images/portfolio/portfolio-1/döviz-kuru-sabitleme.png';
import PortImg3 from '../images/portfolio/portfolio-1/takvim.png';
import PortImg4 from '../images/portfolio/portfolio-1/vip.jpeg';
import PortImg5 from '../images/portfolio/portfolio-1/arkadasini-getir.png';
import PortImg6 from '../images/portfolio/portfolio-1/yatırım-destekcisi.png';
import PortImg7 from '../images/portfolio/portfolio-1/demo-hesap.png';
import miniimg1 from "../images/portfolio/portfolio-1/mini-arkadasını-getir.png"

const kampanya = [
    {
        id: 1,
        img: PortImg1,
        url: "insured-transaction-bonus",
        title: "Sigortalı İşlem Bonusu",
        description: "Günümüz finansal piyasalarında yatırım yaparken güvende hissetme ve hareket alanı oldukça önemlidir. Bu doğrultuda, yatırım ortaklarımız için özel olarak tasarladığımız 'Sigortalı İşlem Bonusu' ile yatırımlarınızı güvence altına alıyor ve bir adım öteye taşıyoruz. Bu yenilikçi kampanyayla, yatırımcılarımıza güvenli bir ortam sunmayı amaçlıyoruz.",
        catchword: "NOVA YATIRIM OLARAK 400 USD’DEN BAŞLAYAN YATIRIMLARINIZIN %20’SİNİ SİGORTALIYORUZ!",
        campaignDetail: "Öncelikle, yatırım ortaklarımızın fon miktarlarının bir kısmını sigortalayarak, yatırımcılarımıza olasık kayıplarını telafi etme şansı sunuyoruz.",
        example: "Örneğin, 400 USD olan ilk fonlama miktarınızı %20'lik sigorta ile beraber hesabınızda 80 USD'lik bir güvence oluşturmuş oluyoruz. Olası yaşanacak kayıp miktarının telafisi için hesap hacmi oluşturuyoruz.",
        programAdvantageTitle1: "",
        programAdvantage1: "",
        programAdvantageTitle2: "",
        programAdvantage2: "",
        programAdvantageTitle3: "",
        programAdvantage3: "",
        programAdvantageTitle4: "",
        programAdvantage4: "",
        programAdvantageTitle5: "",
        programAdvantage5: "",
    },
    {
        id: 2,
        img: PortImg2,
        url: "campaign-to-fix-the-exchange-rate",
        title: "Döviz Kurunu Sabitleme Kampanyası",
        description: "Günümüzde küresel finansal piyasalarda döviz kurları dalgalanmaya açık bir şekilde değişim göstermektedir. Bu değişkenlik, yatırımcıları belirsizliklere karşı daha dikkatli olmaya sevk etmektedir. İşte tam da bu noktada, özel olarak tasarladığımız 'Döviz Kurunu Sabitleme Kampanyası' devreye giriyor. Yatırımcılara belirli bir döviz kuru üzerinden yatırım yapma fırsatı sunarak, dalgalı döviz kurlarının etkilerini minimize etmeyi amaçlıyoruz. Bu kampanyayı özellikle ekonomik karar haftalarında kullanarak, yatırımcılarımıza karar alma süreçlerinde ek bir güvence sağlıyoruz.",
        catchword: "",
        campaignDetail: " Döviz Kurunu Sabitleme Kampanyası, yatırımcılara avantajlı bir döviz kuru üzerinden işlem yapma imkanı tanır.",
        example: "Örneğin, ekonomik takvimin yoğun olduğu haftalarda, avantajlı döviz kuru üzerinden ilk fonlamanızı yapabilir, bu sayede yatırımcılarımız döviz kuru dalgalanmalarından kaynaklanan belirsizlikleri minimize eder ve işlemlerini daha güvenli bir şekilde yönetebilirler.",
        programAdvantageTitle1: "",
        programAdvantage1: "",
        programAdvantageTitle2: "",
        programAdvantage2: "",
        programAdvantageTitle3: "",
        programAdvantage3: "",
        programAdvantageTitle4: "",
        programAdvantage4: "",
        programAdvantageTitle5: "",
        programAdvantage5: ""
    },
    {
        id: 3,
        img: PortImg3,
        url: "invest-week-bonus",
        title: "Yatırım Haftası Bonusu",
        description: "Finansal piyasalarda yatırım yapmak artık daha heyecanlı! Özel olarak düzenlediğimiz 'Yatırım Haftaları' ile yatırımcılarımıza sıra dışı bir deneyim sunuyoruz. Bu kampanya, yatırımlarında başarılı olan kullanıcılarımıza ödüller kazandırmayı ve yatırım rekabetini artırmayı amaçlıyor. Herkesin katılımına açık olan bu yarışma ile yatırımcılarımız hem kazanacak, hem eğlenecek!",
        catchword: "",
        campaignDetail: "‘’Yatırım Haftası Bonusu’’na katılmak için yapmanız gereken çok kolay! Herhangi bir piyasa haftasında başarılı olmak için elinizden gelenin en iyisini yapın. Yatırım haftasının sonunda en yüksek getiriyi elde ederek sürpriz ödülleri kazanabilirsiniz.",
        example: "",
        programAdvantageTitle1: "",
        programAdvantage1: "",
        programAdvantageTitle2: "",
        programAdvantage2: "",
        programAdvantageTitle3: "",
        programAdvantage3: "",
        programAdvantageTitle4: "",
        programAdvantage4: "",
        programAdvantageTitle5: "",
        programAdvantage5: ""
    },
    {
        id: 4,
        img: PortImg4,
        url: "vip-bonus",
        title: "VIP Programı",
        description: " Yatırım hayatınızı inşa ederken ayrıcalıklı bir dünyaya adım atmak ister misiniz? İşte bu hayaliniz oluşturduğumuz VIP Programı ile gerçeğe dönüşüyor. Özellikle aylık 2500 USD işlem hacmine sahip olan kullanıcılarımız için tasarlanan VIP Program, sizi finansal başarıya taşımanın yanında ayrıcalıklar ve avantajlarla dolu bir kampanya sunuyor",
        campaignDetail: "‘’Yatırım Haftası Bonusu’’na katılmak için yapmanız gereken çok kolay! Herhangi bir piyasa haftasında başarılı olmak için elinizden gelenin en iyisini yapın. Yatırım haftasının sonunda en yüksek getiriyi elde ederek sürpriz ödülleri kazanabilirsiniz.",
        catchword: "",
        example: "",
        programAdvantageTitle1: "VIP Bülten ve İşlemler",
        programAdvantage1: "VIP Program üyelerimize özel olarak hazırlanan bültenler ve uzman analistlerimizin işlemleri ile piyasa trendlerini ayrıcalıklı değerlendirme avantajını yakalayın. Uzman analistlerimizin gönderdiği VIP bülten ve işlemlerle, en doğru yatırım aksiyonlarını alabilirsiniz.",
        programAdvantageTitle2: "Aylık Analiz Raporları",
        programAdvantage2: " Finansal piyasalarda doğru ve güvenli hareket etmek için aylık analiz raporları size rehberlik edecek. VIP Program üyelerimize özel olarak hazırlanan detaylı analiz raporları ile piyasadaki gelişmeleri anında takip edebilir ve stratejilerinizi buna göre güncelleyebilirsiniz.",
        programAdvantageTitle3: "Birebir Uzman Desteği",
        programAdvantage3: "VIP Program üyelerimize özel olarak atanacak birebir uzman desteği, yatırımlarınızı daha etkili bir şekilde yönetmenize yardımcı olacak. Uzman desteğimizle beraber stratejilerinizi oluşturabilir ve portföyünüzü optimum şekilde büyütebilirsiniz.",
        programAdvantageTitle4: "Özel Etkinlik ve Seminerler",
        programAdvantage4: " VIP Program üyelerimize özel düzenlenen etkinlikler ve seminerlerle, finansal piyasalardaki gelişmeleri yakından takip edebilirsiniz. Bu etkinliklerde, sektör öncüleri ile tanışma fırsatı da elde edebilirsiniz.",
        programAdvantageTitle5: "VIP Yatırımcı Desteği",
        programAdvantage5: " VIP Program üyelerimize özel olarak ayrılan VIP yatırımcı desteği ile, tüm sorularınıza hızlı ve etkili çözümler elde edebilirsiniz. Size özel bir destek ekibi ile her an yanınızda olacak ve olası sorunlarınıza anında çözüm bulabilirsiniz."
    },
    {
        id: 5,
        url: "bring-your-friend-bonus",
        img: PortImg5,
        title: "Arkadaşını Getir",
        description: "Yatırımlarınızı paylaşmanın ve başarıyı birlikte kutlamanın zamanı geldi! Yatırım dünyasındaki kazanç kapılarını arkadaşlarınızla da paylaşın ve özel bonuslar kazanma şansını yakalayın. 'Arkadaşını Getir, Bonusları Kap!' kampanyamız ile referans olduğunuz her arkadaşınız için size %15, arkadaşınıza başlangıç bonusunda ek %10 bonus sunuyoruz.",
        catchword: "",
        campaignDetail: "",
        example: "",
        programAdvantageTitle1: "",
        programAdvantage1: "",
        programAdvantageTitle2: "",
        programAdvantage2: "",
        programAdvantageTitle3: "",
        programAdvantage3: "",
        programAdvantageTitle4: "",
        programAdvantage4: "",
        programAdvantageTitle5: "",
        programAdvantage5: ""

    },
    {
        id: 6,
        img: PortImg6,
        url: "investment-supporter-awards",
        title: "Yatırım Destekçisi Ödülleri",
        description: " Yatırım dünyasının sosyal yıldızı olma vakti geldi! Siz de platformumuzu sosyal medyada aktif olarak tanıtarak veya belirli etkinliklere katılarak özel ödüller kazanabilirsiniz. En çok etkileşim alan yatırımcılarımıza sürpriz bonuslar ve VIP avantajlar sunuyoruz!",
        catchword: "",
        campaignDetail: "",
        example: "",
        programAdvantageTitle1: "Sosyal Medyada Aktif Olun",
        programAdvantage1: " Platformumuzu sosyal medyada aktif bir şekilde tanıtarak, paylaşımlarınızla dikkat çekin. Yatırım dünyasındaki deneyimlerinizi paylaşın ve diğer kullanıcılar ile etkileşime geçin.",
        programAdvantageTitle2: "Belirli Etkinliklere Katılın",
        programAdvantage2: "Belirlenen etkinliklere katılarak platformumuzdaki topluluğa katkıda bulunun. Eğitim seminerlerine, canlı yayınlara veya özel etkinliklere katılarak deneyimlerinizi paylaşın ve özel ödüllerin sahibi olun.",
        programAdvantageTitle3: "",
        programAdvantage3: "",
        programAdvantageTitle4: "",
        programAdvantage4: "",
        programAdvantageTitle5: "",
        programAdvantage5: ""
    },
    {
        id: 7,
        img: PortImg7,
        url: "demo-account-competition",
        title: "Demo Hesap Yarışması",
        description: " Yatırım dünyasına hoş geldiniz! Deneme hesabımızı başarıyla kullanarak belirli bir hacme ulaşan kullanıcılarımıza özel bir fırsat sunuyoruz. Artık demo hesap başarınızı gerçek hesapla taçlandırmanın vakti geldi! İşte size özel avantajlarla dolu bu geçişin detayları",
        campaignDetail: "",
        catchword: "",
        example: "",
        programAdvantageTitle1: "Sosyal Medyada Aktif Olun",
        programAdvantage1: " Platformumuzu sosyal medyada aktif bir şekilde tanıtarak, paylaşımlarınızla dikkat çekin. Yatırım dünyasındaki deneyimlerinizi paylaşın ve diğer kullanıcılar ile etkileşime geçin.",
        programAdvantageTitle2: "Belirli Etkinliklere Katılın",
        programAdvantage2: "Belirlenen etkinliklere katılarak platformumuzdaki topluluğa katkıda bulunun. Eğitim seminerlerine, canlı yayınlara veya özel etkinliklere katılarak deneyimlerinizi paylaşın ve özel ödüllerin sahibi olun.",
        programAdvantageTitle3: "",
        programAdvantage3: "",
        programAdvantageTitle4: "",
        programAdvantage4: "",
        programAdvantageTitle5: "",
        programAdvantage5: ""
    }
]





export default kampanya;