import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Masonry from 'react-masonry-component';
import kampanyalar from "../../../data/kampanya"
import kampanyaEN from "../../../data/kampanyaEN"
// Images
import PortImg1 from './../../../images/portfolio/portfolio-1/image_1.jpg';
import PortImg2 from './../../../images/portfolio/portfolio-1/image_2.jpg';
import PortImg3 from './../../../images/portfolio/portfolio-1/image_3.jpg';
import PortImg4 from './../../../images/portfolio/portfolio-1/image_4.jpg';
import PortImg5 from './../../../images/portfolio/portfolio-1/image_5.jpg';
import PortImg6 from './../../../images/portfolio/portfolio-1/image_6.jpg';
import PortImg7 from './../../../images/portfolio/portfolio-1/image_7.jpg';
import PortImg8 from './../../../images/portfolio/portfolio-1/image_8.jpg';
import PortImg9 from './../../../images/portfolio/portfolio-1/image_9.jpg';
import PortImg10 from './../../../images/portfolio/portfolio-1/image_10.jpg';
import PortImg11 from './../../../images/portfolio/portfolio-1/image_11.jpg';
import PortImg12 from './../../../images/portfolio/portfolio-1/image_12.jpg';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import kampanya from '../../../data/kampanya';
import i18n from '../../../i18n';

function PortfolioContent() {

	let location = useLocation();
	const currentLang = i18n.language
	console.log(kampanyaEN);
	return (
		<>


			<Masonry className="row sp20">

				{currentLang && currentLang === "en" ?

					kampanyaEN.map((item, index) => (

						<div className="action-card col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
							<div className="portfolio-box style-3">
								<div className="portfolio-media">
									<a href={"/campaigns" + "/" + item.url}>
										<img src={item.img} alt="" />
									</a>
								</div>
								<div className="portfolio-info">
									<h4 className="title"><Link to={"/campaigns" + "/" + item.url}>{item.title}</Link></h4>

									{/* <span className="exe-title">{item.description}</span>	 */}
								</div>
							</div>
						</div>
					))
					:
					kampanya.map((item, index) => (

						<div className="action-card col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
							<div className="portfolio-box style-3">
								<div className="portfolio-media">
									<a href={"/campaigns" + "/" + item.url}>
										<img src={item.img} alt="" />

									</a>
								</div>
								<div className="portfolio-info">
									<h4 className="title"><Link to={"/campaigns" + "/" + item.url}>{item.title}</Link></h4>

									{/* <span className="exe-title">{item.description}</span>	 */}
								</div>
							</div>
						</div>
					))

				}
			</Masonry>

		</>

	);
}

class PortMasonry extends Component {
	render() {
		return (
			<>
				<PortfolioContent />
			</>
		)
	}
}
export default PortMasonry;