import img1 from "../images/yatirim/1.jpeg"
import img2 from "../images/yatirim/2.jpeg"
import img3 from "../images/yatirim/3.jpeg"
import img4 from "../images/yatirim/4.jpeg"


const yatirimEN = [{
    id: 1,
    img:img1,
    url:"emtialar",
    title: "Commodities",
    descriptionTitle1: "Gold and Silver",
    descriptionTitle2: "Agricultural Products",
    description1: "Precious metals, preferred for value preservation, are known as a safe haven during times of economic uncertainty.",
    description2: "Agricultural products such as wheat, cotton, and coffee can fluctuate based on factors like global food demand and weather conditions."
        
},
{
    id:2,
    img:img2,
    url:"hisse-senetleri",
    title: "Stocks",
    descriptionTitle1: "Technology Companies",
    descriptionTitle2: "Energy Companies",
    description1: "Innovative and high-growth potential technology companies can add value to stock portfolios.",
    description2: "Energy companies such as oil and gas companies can offer opportunities to investors influenced by energy demand."
},
{
    id:3,
    img:img3,
    url:"forex",
    title: "Forex (Foreign Exchange)",
    descriptionTitle1: "Pairs like EUR/USD, GBP/JPY",
    descriptionTitle2: "Turkish Lira Pairs like USD/TRY, EUR/TRY",
    description1: "Global economic developments and central bank policies can influence foreign exchange rates.",
    description2: "Turkish Lira pairs like USD/TRY, EUR/TRY can be an option to assess the effects on the value of the Turkish Lira."
},
{
    id:4,
    img:img4,
    url:"kripto-paralar",
    title: "Cryptocurrencies",
    descriptionTitle1: "Bitcoin (BTC), Ethereum (ETH)",
    descriptionTitle2: "Altcoins",
    description1: "Cryptocurrencies built on blockchain technology can play a significant role in the future of digital assets.",
    description2: "Various altcoins like Litecoin (LTC), Ripple (XRP) represent different blockchain projects."
}
]

export default yatirimEN