import React, { Component } from 'react';
import Slider from "react-slick";
import { withTranslation } from 'react-i18next';

// Images
import TestiPic from "../../../images/testimonials/Nova-Logo.png"
import i18n from '../../../i18n';
// import TestiPic2 from "../../../images/testimonials/pic2.jpg"
// import TestiPic3 from "../../../images/testimonials/pic3.jpg"
// import TestiPic4 from "../../../images/testimonials/pic4.jpg"
const currentLang = i18n.language



class Testimonial2 extends Component {
	render() {
		const { t } =this.props;

		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1600,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
		const content = [

			{
				thumb: TestiPic,
				name: "Mustafa Çakıl",
				work: `${t('Bankacı')}`,
				text: `${t('Nova Invest, teknoloji sektöründeki potansiyeli değerlendirme konusunda beni etkileyen bir platform oldu. Geniş yatırım yelpazesi sayesinde, sektördeki öncü teknoloji şirketlerine olan yatırımımı çeşitlendirdim Ayrıca, teknik analiz eğitimleri sayesinde piyasayı daha iyi okumaya başladım.')}`,
			},
			{
				thumb: TestiPic,
				name: "Gülnaz Yiğit",
				work:`${t('Hemşire')}`,
				text: `${t("Nova Invest'ın enerji sektöründeki fırsatları değerlendirmek için sağladığı global enstrüman alternatifi benim için çok kazançlı oldu. Enerji projelerine yönelik detaylı yatırım stratejileri ile, sektördeki potansiyeli görmem sağlandı.")}`,
			},
			{
				thumb: TestiPic,
				name: "Bora Altuner",
				work: `${t('Mühendis')}`,
				text: `${t('Londra merkezli Nova Invest, sağlık sektöründeki şirketlere yönelik gelişmeleri takip etmemde çok yardımcı oldu. Detaylı ve anlaşılabilir analizleri sayesinde sağlık sektöründeki gelişmeleri takip etmek ve yatırım yapmak konusunda işimi kolaylaştırdılar.')}`,
			},
			{
				thumb: TestiPic,
				name: "Soner Seven",
				work: `${t('Yönetici')}`,
				text: `${t("Nova Invest'ın sunduğu geniş enstrüman yelpazesi sayesinde, gıda ve tarım sektöründeki fırsatları değerlendirmeye başladım. Küresel araştırmaları ile dünya genelindeki tarım ve gıda şirketlerine yatırım yapma imkanı sunmaları, portföyümü çeşitlendirme konusunda benim için önemli bir avantaj oldu.")}`,
			}
		]
		return (

			<>
				<Slider {...settings} className="testimonial-carousel owl-btn-center-lr owl-btn-1 testimonial-right">
					{content.map((item, id) => (
						<div className="slider-item">
							<div className="testimonial-bx">
								<div className="testimonial-content">
									<p>{item.text}</p>
								</div>
								<div className="testimonial-info">
									<h5 className="name">{item.name}</h5>
									<p>{item.work}</p>
								</div>
								<div className="testimonial-pic">
									<img src={item.thumb} alt="" />
								</div>
							</div>
						</div>
					))}
				</Slider>
			</>
		);
	}
}

export default withTranslation() (Testimonial2);