import React, { Component } from 'react';
import { Link as ScrollTo } from 'react-scroll';

// Layout
import Header from '../layout/header/header1';
import Footer from '../layout/footer/footer1';

// Elements
// Images
import bannerPic2 from '../../images/banner/yatırım.png';
import pattern1 from '../../images/pattern/pt1.png';
import { useTranslation } from 'react-i18next';


function Kvkk() {
	const { t, i18n } = useTranslation();

    return (
        <>
            <Header />

            <div className="page-content bg-white">
                <div className="page-banner ovbl-dark page-banner-lg ovdt2 overlay-dotted no-pt parallax" style={{ backgroundImage: "url(" + bannerPic2 + ")" }}>
                    <div className="container">
                        <div className="page-banner-entry">
                            <span className="banner-sm-title">KVKK</span>
                            {/* <h1 className="text-white">We are not your average<br/> brand agency</h1> */}
                        </div>
                    </div>
                    <ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
                </div>

                <div className="content-block" id="content-area">


                    {/* <div className="section-area">
							<img src={portFullPic1} alt=""/>
						</div> */}

                    <div className="section-area section-sp2 bg-gray" style={{ backgroundImage: "url(" + pattern1 + ")"}}>
                        <div className="container-max wow fadeIn" data-wow-delay="0.4s">
                            <div className="heading-bx text-center">
                                <h2 className="title-head m-b0">{t("Kişisel Verilerin Korunması Kanunu (KVKK) Metni")}</h2>
                                <p className='kvkk-text'>{t('Kişisel verilerinizi aşağıdaki amaçlar doğrultusunda toplamakta ve işlemekteyiz:')}
                                        <br/>
                                    {t('Müşteri ilişkileri yönetimi, ürün ve hizmet sağlama, sipariş işleme ve teslimat, müşteri hizmetleri sunma gibi işlemler için gerekli olan kişisel verilerin toplanması ve işlenmesi.')}
                                    {t('Pazarlama ve reklam faaliyetlerinde kullanılmak üzere kişisel verilerin toplanması ve işlenmesi. İş güvenliği, güvenlik önlemleri ve yetkilendirme amaçlarıyla kişisel verilerin toplanması ve işlenmesi. Yasal yükümlülüklerin yerine getirilmesi ve hukuki savunma gerekliliklerine uygun olarak kişisel verilerin toplanması ve işlenmesi.')}</p>
                               
                                <h3 className='kvkk-text'>{t('Kişisel Verilerin Paylaşılması')}</h3>
                                <p className='kvkk-text'>{t('Kişisel verilerinizi, yukarıda belirtilen amaçlarla uyumlu olarak aşağıdaki üçüncü taraflarla paylaşabiliriz:')}</p>

                                <h5 className='kvkk-text' style={{ fontWeight: "bold" }}>{t('Hizmet sağlayıcılar:')}</h5>
                                <p className='kvkk-text'> {t('İşimizi yürütmek için dışarıdan hizmet sağlayıcılarla çalışabiliriz. Bu hizmet sağlayıcılar, kişisel verilerinizi yalnızca belirtilen amaçlar doğrultusunda işler ve korur.')}</p>
                                
                                <h5 className='kvkk-text' style={{ fontWeight: "bold" }}>{t('İş ortakları:')}</h5>
                                <p className='kvkk-text'>{t('Bazı durumlarda, iş ortaklarımızla birlikte çalışabiliriz ve kişisel verilerinizi ortak pazarlama faaliyetlerinde kullanabiliriz. Ancak, verilerinizi paylaşmadan önce onayınızı alacağız.')}</p>
                               
                                <h5 className='kvkk-text' style={{ fontWeight: "bold" }}>{t('Hukuki gereklilikler:')}</h5>
                                <p className='kvkk-text'>{t('Yasal düzenlemeler veya mahkeme kararları gereği kişisel verilerinizi yetkili makamlarla paylaşabiliriz.')}</p>
                                
                                <h3 className='kvkk-text'>{t('Kişisel Verilerin Saklanması')}</h3>
                                <p className='kvkk-text'>{t('Kişisel verilerinizi, yukarıda belirtilen amaçlarla sınırlı bir süre boyunca saklamaktayız. Verilerinizi, ilgili mevzuata uygun olarak korur ve gereksiz olduğunda sileriz.')}</p>

                                <h3 className='kvkk-text'>{t('Kişisel Veri Sahibinin Hakları')}</h3> <br/>
                                <h6 className='kvkk-text'>{t('KVKK kapsamında, kişisel verileriniz üzerinde aşağıdaki haklara sahipsiniz:')}</h6>
                                <p className='kvkk-text'>{t('Kişisel verilerinizin işlenip işlenmediğini öğrenme,')}</p>
                                <p className='kvkk-text'>{t('Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,')}</p>
                                <p className='kvkk-text'>{t('Kişisel verilerinizin yurt içinde veya yurt dışında aktarıldığı üçüncü kişileri öğrenme')}</p>
                                <p className='kvkk-text'>{t('Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde düzeltilmesini isteme')}</p>
                                <p className='kvkk-text'>{t('Kişisel verilerinizin KVKK ve ilgili mevzuata uygun olarak silinmesini veya yok edilmesini isteme,')}</p>
                                <p className='kvkk-text'>{t('Kişisel verilerinizin otomatik sistemler vasıtasıyla analiz edilmesi durumunda aleyhinize bir sonucun ortaya çıkmasına itiraz etme,')}</p>
                                <p className='kvkk-text'>{t('Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız halinde maddi veya manevi tazminat talep etme.')}</p>
                                <br/>
                                <p className='kvkk-text'>{t('Yukarıda belirtilen haklarınızı kullanmak için bize iletişim bilgilerimiz üzerinden başvuruda bulunabilirsiniz.')}</p>

                            </div>



                        </div>
                    </div>



                </div>

            </div>

            <Footer />

        </>
    );

}

export default Kvkk;