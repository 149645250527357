import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll'
import { Nav, Tab } from 'react-bootstrap'
import connector from "../../../data/connector"
// Layout
import Header from "../../layout/header/header1";
import Footer from "../../layout/footer/footer1";

// Images
import bannerPic1 from '../../../images/banner/banner1.jpg';
import pattern1 from '../../../images/pattern/pt1.png';
import ReactInputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';

function MyAccount() {
	const { t, i18n } = useTranslation();
	let errorMessage = `${t('Bir Hata Oluştu! Lütfen sayfayı yenileyip tekrar deneyin.')}`;
	let successMessage = `${t('İsteğiniz Alındı! Ekibimiz en kısa süre içerisinde sizinle iletişime geçecektir.')}`
	const [name, setName] = useState("");
	const [surname, setSurname] = useState("")
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [validator, setValidator] = useState(false);
	const [isNull, setIsNull] = useState(false);
	const [kvkk, setKvkk] = useState(false);
	const [isSend, setIsSend] = useState(false);
	const [sca, setSca] = useState("")
	const [formUrl, setFormUrl] = useState()
	const [message, setMessage] = useState("")

	useEffect(() => {
		setSca(`${'https://sca.' + (window.location.hostname.replace('www.', ''))}`)

	})
	useEffect(() => {
		setFormUrl(`${'https://' + (window.location.hostname.replace('www.', '')) + "/api/v1/Submit?brand=2"} `)
		//setFormUrl(`${'https://' + "nova.testmedici.com" + "/api/v1/Submit?brand=2"} `)
	})

	

	var data = {
		"firstName": name,
		"lastName": surname,
		"phone": String(phone).replace(" ", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", ""),
		"email": email,
		"approvedConditions": kvkk,
	}

	

	useEffect(() => {
		if (name === "" || surname === ""  || email === ""  || phone === ""  || kvkk === false  || phone.includes("_")  || validator === false) {
			setIsNull(false)
		}
		else {
			setIsNull(true)
		}
	}, [name, surname, email, phone, validator, kvkk])

	function EmailChange(e) {
		const validEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		let isOk = validEmail.test(String(e).toLowerCase())
		if (isOk) {
			setEmail(e);
			setValidator(true)
		}
		else {
			setEmail(e);
			setValidator(false)
		}
	}
	async function Submit() {
		setIsSend(true)
		connector(formUrl, data)
			.then(data => {
				if (data.success === true) {
					setMessage(successMessage)
					setIsSend(false);
				}
				else {
					setMessage(errorMessage)

					setIsSend(false)
				}
			})
		
		// .catch(err => {
		//     console.log(err);
		// })

		
		setName("");
		setSurname("");
		setEmail("");
		setPhone("");
		setMessage("")
	}
	return (
		<>
			<Header />

			<div className="page-content bg-white" >
				{/* <div className="page-banner ovpr-dark overlay-dotted ovdt2 parallax" style={{backgroundImage: "url("+bannerPic1+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">Hesap Oluştur</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i>Anasayfa</Link></li>
										<li>Hesap Oluştur</li>
									</ul>
								</div>
							 </div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
					</div> */}
				<div className="content-block" id="content-area">

					<div className="section-area section-sp2 bg-gray" style={{ backgroundImage: "url(" + pattern1 + ")",backgroundColor: "#1c1a8854 "}}>
						<div className="container wow fadeIn" data-wow-delay="0.4s">
							<div className="heading-bx text-center">
								<h2 className="title-head m-b0">{t('Hesap Oluştur')}</h2>
								<p className="m-b0">{t('Nova Invest  Güçlü Bir Yatırımın Anahtarı!')}</p>
							</div>
							<div className="row">
								<div className="col-lg-12 col-md-12 col-sm-12">
									<div className="my-account">
										<Tab.Container defaultActiveKey="tabOne">
											<Tab.Content>
												<Nav className="item-list" style={{ display: "flex", justifyContent: "center", alignItems: "center", display: 'none' }}>
													{/* <Nav.Item><Nav.Link eventKey="tabOne">Login</Nav.Link></Nav.Item> */}
													<Nav.Item><Nav.Link eventKey="tabOne">{t('Kayıt Ol')}</Nav.Link></Nav.Item>
												</Nav>
												{/* <Tab.Pane eventKey="tabOne">
														<form action="#">
															<div className="form-group">
																<input type="text" className="form-control" id="LoginUserName" aria-describedby="home-tab" placeholder="Username"/>
															</div>
															<div className="form-group">
																<input type="password" className="form-control" id="LoginPassword" aria-describedby="home-tab" placeholder="password"/>
															</div>
															<div className="form-group">
																<button type="button" className="btn primary radius-xl">login</button>
																<button type="button" className="btn-link primary forgot-pass">Forgot Password ?</button>
															</div>											
														</form>
													</Tab.Pane> */}
												<Tab.Pane eventKey="tabOne">
													<form action="#">
														<div className="form-group">
															<input type="text" required className="form-control" value={name} onChange={event => { setName(event.target.value) }} placeholder={`${t('İsim')}`} />
														</div>
														<div className="form-group">
															<input type="text" required className="form-control" value={surname} onChange={event => { setSurname(event.target.value) }} placeholder={`${t('Soyisim')}`} />
														</div>
														<div className="form-group">
															<ReactInputMask mask="(999) 999 99 99" type="text" required className="form-control" value={phone} onChange={event => { setPhone(event.target.value) }} placeholder={`${t('Telefon Numarası')}`} />
														</div>
														<div className="form-group">
															<input required type="email" value={email} onChange={event => { EmailChange(event.target.value) }} className="form-control" placeholder={`${t('E-Mail Adresi')}`} />
														</div>
														<div className="col-lg-12">
															<div className="form-group">
																{data.success === true ?
																	<p style={{ color: "green" }}>{message}</p>
																	:
																	<p style={{ color: "green" }}>{message}</p>
																}
															</div>
														</div>
														<div className="col-12"  style={{fontSize:"14px"}}>
															<input required type="checkbox" onClick={() => setKvkk(kvkk ? false : true)} />
															<span><a href='/kvkk' target='_blank' style={{ textDecoration: "underline" }}>{t('Kişisel Verilerin Korunması ve İşlenmesi Sözleşmesi')}</a></span>
														</div><br/>
														<div className="col-5">
															<div className="send-btn">
																<div className=" btn primary radius-xl" style={{padding:"8px"}}>
																	{isNull ?
																		<button type="submit" value="submit" onClick={() => { Submit() }} className="btn default-btn">{t('Kayıt Ol')}</button>
																		:
																		<button type="submit" value="submit" className="btn default-btn">{isSend ? `${t('Gönderiliyor')}` : `${t('Kayıt Ol')}`}</button>
																	}
																</div>
															</div>
														</div>
														<br/>
														<div className="col-lg-12" style={{fontSize:"14px"}}>
															<span>{t('Zaten hesabınız var mı?')} <a href={sca} ><a>{t('Giriş Yap')}!</a></a></span>
														</div>
													</form>
												</Tab.Pane>
											</Tab.Content>
										</Tab.Container>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>

			<Footer />

		</>
	);

}

export default MyAccount;